import React, { Component } from 'react';
import { connect } from 'react-redux';
import BotSwitcher from './modules/BotSwitcher';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faSync, faLink, faQuestionCircle, faCrown, faBars } from '@fortawesome/free-solid-svg-icons';
import Notifications from './elements/Notifications';
import history from '../../history';
import GettingStarted from './elements/GettingStarted';
import { Link } from "react-router-dom";
import SaleBanner from './SaleBanner';
import BotDropdown from './modules/BotDropdown';



class HeaderMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openBotSwitcher: false,
            openNotifications: false,
            unread: false,
            showHelper: false,
            mobileNav: false,
            sale: false
        };
    }

    componentDidMount() {
        this.checkUnreadNotifications();
        if (!this.props.premium && !this.state.sale) {
            this.setState({ sale: true });
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (!this.props.premium && !this.state.sale) {
            this.setState({ sale: true });
        }

        if (this.props.notifications != prevProps.notifications || this.props.user.notifications != prevProps.user.notifications) {
            this.setState({ unread: false });
            this.checkUnreadNotifications();
        }
    }


    renderName = () => {
        var name = "";
        if (this.props.bot.name != "") {
            return this.props.bot.name;
        }
        this.props.bots.forEach(bot => {
            if (bot.id == this.props.bot.id) {
                name = bot.username;
            }
        });
        return name;
    };

    renderImage = () => {
        var img = "";
        if (this.props.bot.img != "" && this.props.bot.img != null) {
            return this.props.bot.img;
        }
        this.props.bots.forEach(bot => {
            if (bot.id == this.props.bot.id) {
                img = bot.avatar;
            }
        });
        return img;
    };

    closeBotSwitcher = () => {
        this.setState({ openBotSwitcher: false });
    };

    closeNotifications = () => {
        this.setState({ openNotifications: false });
    };

    notificationsClick = (e) => {
        if (e.currentTarget == e.target) {
            this.closeNotifications(e);
        }
    };

    checkUnreadNotifications = () => {
        var user = { ...this.props.user };
        if (user.notifications == undefined) {
            user.notifications = [];
        }

        this.props.notifications.forEach(notification => {
            if (!(user.notifications.includes(notification.id))) {
                this.setState({ unread: true });
            }
        });
    };

    render() {
        return (
            <>
                {!this.props.premium ? <SaleBanner /> : null}
                <header className="dash-header  h-[80px] lg:h-[60px]" style={{
                    top: "0px",
                    marginTop: !this.props.premium ? "30px" : "0px"
                }} >
                    {this.state.showHelper == true ? <GettingStarted closeModal={() => { this.setState({ showHelper: false }); }} /> : null}
                    {this.state.openBotSwitcher ? <BotSwitcher close={this.closeBotSwitcher}></BotSwitcher> : null}

                    <div className="header-item">
                        <div className="header-logo">
                            <Link to="/dashboard">
                                <img src={process.env.PUBLIC_URL + '/images/logo-red.png'}></img>
                            </Link>
                        </div>
                    </div>

                    <div className="header-item">
                        <nav className="dash-header-menu">
                            <ul>
                                <li><a href="https://botghost.com/">Home</a></li>
                                {/* <li><a href="https://botghost.com/features">Features</a></li> */}
                                <li><a href="https://botghost.com/community/guides" target="_blank">Guides</a></li>
                                {/* <li><a href="https://botghost.com/blog">Blog</a></li> */}
                                <li><a href="https://youtube.com/c/BotGhostTutorials?sub_confirmation=1" target="_blank">Tutorials</a></li>
                                <li><a href="https://botghost.com/tools" target="_blank">Tools</a></li>
                                <li><a href="https://discord.com/invite/9UPM6S4xyA" target="_blank">Updates</a></li>
                                <li><a href="https://docs.botghost.com" target="_blank">Documentation</a></li>
                                {/* <li><a href="https://forms.gle/7P7EjQTKskrU7Hmy6" target="_blank">Partnerships</a></li> */}
                                {/* <li><a href="https://botghost.com/partners" target="_blank">Partner Program</a></li> */}
                                <li><a href="https://discord.com/invite/9UPM6S4xyA" target="_blank">Support Server</a></li>
                                <li><a href="https://botpanel.gg/" target="_blank">Dashboard Creator <span className='bg-blue text-white px-2 rounded-md'>New!</span></a></li>

                            </ul>
                        </nav>
                    </div>

                    <div className='ml-auto px-2 lg:invisible'>
                        <BotDropdown />
                    </div>

                    <div className="header-item header-item-right ml-0 md:ml-auto">
                        <nav className="dash-header-menu menu-botswitcher">
                            <ul>
                                {!this.props.bot.premium && <li style={{ marginRight: "30px" }}>
                                    <Link to={
                                        this.props.user.tokens > 0 ? "/dashboard/settings" : "/dashboard/premium"
                                    } className={`'btn btn-red p-2 px-4 rounded-md hover:!text-white !text-white ${this.props.user.tokens > 0 ? "!bg-red animate-pulse" : "!bg-[#ffb632]"} call-to-action`} style={{
                                        backgroundColor:
                                            this.props.user.tokens < 0 ? "#f45142 !important" : "rgb(255 182 50) !important", padding: "3px 15px"
                                    }}>{
                                            this.props.user.tokens > 0 ? "Upgrade this Bot!" : "Get Premium"
                                        } <FontAwesomeIcon icon={faCrown} /></Link>
                                </li>}


                                {/* <li id="header-bot-switcher" style={{ marginRight: "15px" }} onClick={(e) => { this.setState({ openBotSwitcher: true }); }}><img className="dash-image" src={this.renderImage() || "https://res-3.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco/v1440924046/wi1mlnkbn2jluko8pzkj.png"}></img><a style={{ paddingLeft: "5px" }} href="#">{this.renderName()} </a></li> */}


                                <div className="mobile-bars" style={{ marginRight: "5px" }}>
                                    <li onClick={() => this.props.mobileAction("open")}><FontAwesomeIcon icon={faBars} fixedWidth style={{ color: "white", fontSize: "32px" }} /></li>
                                </div>

                            </ul>
                        </nav>
                    </div>
                </header>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    page: state.page,
    bot: state.data.bot,
    premium: state.data.premium,
    bots: state.data.bots,
    user: state.data.user,
    notifications: state.notifications
});


export default connect(mapStateToProps)(HeaderMenu);