import React, { Component } from 'react';



class Toggle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false
        };
    }

    render() {
        return (
            <div onClick={(e) => { this.props.update(!this.props.value, this.props.type); }} className={`${this.props.hithere == true ? "hithere" : null} ${this.props.premiumToggle ? "premium-toggle" : null} toggle ${this.props.value ? 'checked' : ""} `}>
                <div style={{ left: this.props.value ? "33px" : "3px" }} className="toggle-circle"></div>
            </div>
        );
    }
}

export default Toggle;