import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '../../../history';
import PremiumRequiredModal from './PremiumRequiredModal';

export class ModuleSideMenuItem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            active: false,
            premiumModal: false,
            premiumMessage: ""


        };
    }

    componentDidUpdate(prevProps, prevState) {
        // Check if the location has changed
        if (this.props.location !== prevProps.location) {
            if (window.location.pathname == `/dashboard/module/${this.props.id}/${this.props.name.toLowerCase().split(" ").join("")}`) {
                this.setState({ active: true });
            } else {
                this.setState({ active: false });
            }
        }
    }

    checkCustomModuleEnabled = (id) => {
        var enabled = false;
        if (this.props.moduleSettings[id] != undefined && this.props.moduleSettings[id].enabled == true) {
            enabled = true;
        }
        return enabled;
    };
    render() {
        return (
            <li className={` dash-sidebar-item ${this.props.premiumRequired == true && this.props.premium == false ? "side-menu-premium" : null}`} onClick={() => {
                // history.push(`/dashboard/module/${this.props.id}/${this.props.name.toLowerCase().split(" ").join("")}`);
                if (this.props.premiumRequired == true && this.props.premium == false) {
                    this.setState({ premiumModal: true });
                } else {
                    this.props.changePage(this.props.id, this.props.name.toLowerCase().split(" ").join(""));
                }
                // this.forceUpdate();
            }}>
                {this.state.premiumModal && <PremiumRequiredModal open={this.state.premiumModal} close={() => { this.setState({ premiumModal: false }); }} premiumMessage={this.state.premiumMessage} />}
                <span className={`dash-sidebar-item ${this.props.active ? 'active' : ""}`}>
                    <div className={`${this.props.mobile != true ? this.checkCustomModuleEnabled(this.props.id) == true ? "dash-sidebar-item-icon-enabled" : "dash-sidebar-item-icon-disabled" : ""}`} ><img src={this.props.img} className="w-[15px] h-[15px] mr-[15px] rounded-full"></img></div> {this.props.name}
                </span>
            </li>
        );
    }
}

const mapStateToProps = (state) => ({
    moduleSettings: state.data.bot.commands.moduleSettings,
    modules: state.data.modules,
    premium: state.data.premium,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModuleSideMenuItem);