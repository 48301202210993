import React from 'react';
import { connect } from 'react-redux';

const baseFeatures = [
    {
        section: "Bot Features",
        items: [
            {
                name: "Servers Handled",
                free: "5",
                premium: "Unlimited"
            },
            {
                name: "Custom Commands",
                free: "Limited to 3",
                premium: "Unlimited"
            },
            {
                name: "Custom Events",
                free: "Limited to 3",
                premium: "Unlimited"
            },
            {
                name: "Custom Status",
                free: false,
                premium: true
            },
            {
                name: "BotGhost Branding",
                free: "Included",
                premium: "Removed"
            },
            {
                name: "Priority Support",
                free: false,
                premium: true
            }
        ]
    }
];

const PricingTable = ({ custom_modules }) => {
    // Combine base features with module features
    const getAllFeatures = () => {
        let allFeatures = [...baseFeatures];

        if (custom_modules) {
            // Group for premium-only modules
            let premiumModules = {
                section: "Premium Modules",
                items: []
            };

            custom_modules.forEach(module => {
                // Skip hidden modules
                if (module.hidden) return;

                // If it's a premium-only module
                if (module.premium === true) {
                    premiumModules.items.push({
                        name: module.name,
                        free: false,
                        premium: true
                    });
                }
                // If it has feature_array
                else if (module.features_array && module.features_array.length > 0) {
                    allFeatures.push({
                        section: module.name,
                        items: module.features_array.map(feature => ({
                            name: feature.name,
                            free: feature.free,
                            premium: feature.premium
                        }))
                    });
                }
            });

            // Only add premium modules section if there are any
            if (premiumModules.items.length > 0) {
                allFeatures.push(premiumModules);
            }
        }

        return allFeatures;
    };

    const renderCheckmark = (value) => {
        if (typeof value === 'boolean') {
            return value ? (
                <span className="text-green-500 text-xl">✓</span>
            ) : (
                <span className="text-red-500 text-xl">✕</span>
            );
        }
        return value;
    };

    return (
        <div className="max-w-7xl mx-auto px-4">
            <div className="overflow-hidden rounded-lg border border-[#374151] bg-[#1A1A1A] shadow-lg">
                {/* Main Header */}
                <div className="grid grid-cols-3 bg-[#1A1A1A] p-6">
                    <div className="col-span-1 text-left pl-6">
                        <span className="text-white font-bold text-lg">Features</span>
                    </div>
                    <div className="text-center">
                        <div className="inline-block">
                            <span className="bg-blue-600 text-white text-sm font-semibold px-4 py-1 rounded-full">
                                Free
                            </span>
                        </div>
                    </div>
                    <div className="text-center">
                        <div className="inline-block">
                            <span className="bg-[#FFD700] text-black text-sm font-semibold px-4 py-1 rounded-full">
                                Premium
                            </span>
                        </div>
                    </div>
                </div>

                {/* Feature Sections */}
                {getAllFeatures().map((section, sectionIndex) => (
                    <div key={sectionIndex}>
                        {/* Section Header */}
                        <div className="bg-[#1A1A1A] border-t border-b border-[#374151]">
                            <h3 className="text-white text-lg font-medium p-4 pl-6">
                                {section.section}
                            </h3>
                        </div>

                        {/* Section Items */}
                        {section.items.map((feature, index) => (
                            <div
                                key={index}
                                className="grid grid-cols-3 p-4 hover:bg-[#242424] transition-colors"
                            >
                                <div className="col-span-1 pl-6">
                                    <span className="text-[#E5E7EB] font-medium">{feature.name}</span>
                                </div>
                                <div className="text-center text-[#9CA3AF]">
                                    {renderCheckmark(feature.free)}
                                </div>
                                <div className="text-center text-[#FFD700]">
                                    {renderCheckmark(feature.premium)}
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    custom_modules: state.data.modules
});

export default connect(mapStateToProps)(PricingTable);