import React from 'react';
import { connect } from 'react-redux';
import history from "../history";

export const ChristmasSale = () => {
    const handleGetStarted = () => {
        history.push('/dashboard/premium');
    };

    return (
        <div className="relative overflow-hidden bg-[#1a0f0f] p-6 sm:p-12 rounded-2xl my-4 text-white">
            {/* Dark gradient overlay */}
            <div className="absolute inset-0 bg-gradient-to-r from-[#1a0f0f] via-[#2a1515] to-[#1a0f0f]" />

            {/* Enhanced Red/Green Holiday Accent Glows */}
            <div className="absolute -bottom-32 right-0 w-2/3 h-[150%] bg-gradient-to-tl from-[#c41e3a]/30 via-[#228B22]/20 to-transparent blur-3xl" />
            <div className="absolute top-0 right-0 w-1/2 h-full bg-gradient-to-bl from-[#228B22]/25 via-[#c41e3a]/15 to-transparent blur-2xl" />

            {/* Additional subtle holiday accent */}
            <div className="absolute inset-0 bg-gradient-to-r from-transparent via-[#c41e3a]/[0.05] to-[#228B22]/[0.08]" />

            <div className="flex flex-col lg:flex-row items-center justify-between relative z-10 gap-8 lg:gap-0">
                {/* Content */}
                <div className="relative z-10 w-full lg:max-w-xl">
                    <h1 className="text-3xl sm:text-4xl font-bold mb-3">
                        Holiday Special:{' '}
                        <span className="text-[#c41e3a] whitespace-nowrap">
                            50% OFF Premium
                        </span>
                    </h1>

                    <p className="text-base sm:text-lg leading-relaxed text-gray-300 mb-5 lg:pr-12">
                        Upgrade your bot this holiday season! Use code <span className="font-bold text-[#228B22]">CHRISTMAS50</span> for 50% off yearly unlimited or lifetime unlimited plans. Valid until January 1st, 2025.
                    </p>

                    <button
                        onClick={handleGetStarted}
                        className="relative w-full sm:w-auto px-8 py-3 rounded-lg font-semibold overflow-hidden
                            transition-all duration-200 hover:-translate-y-0.5 active:translate-y-0 
                            group cursor-pointer"
                    >
                        {/* Gradient background */}
                        <div className="absolute inset-0 bg-gradient-to-r from-[#c41e3a] via-[#d42e4a] to-[#c41e3a] 
                            group-hover:opacity-90 transition-opacity" />

                        {/* Shine effect */}
                        <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/10 to-transparent 
                            translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-1000" />

                        {/* Button shadow */}
                        <div className="absolute inset-0 shadow-xl shadow-[#c41e3a]/20" />

                        {/* Button text */}
                        <span className="relative z-10">Upgrade Now</span>
                    </button>
                </div>

                {/* Promo Image - Hidden on mobile */}
                <div className="hidden lg:block relative z-10 w-[600px]">
                    <div className="transform scale-125">
                        <img
                            src={process.env.PUBLIC_URL + "/images/christmas.png"}
                            alt="Christmas Sale"
                            className="w-full h-auto rounded-lg transform hover:scale-105 transition-transform duration-500"
                        />
                    </div>
                </div>
            </div>

            {/* Floating snowflakes decoration - Moved to front */}
            <div className="absolute top-0 left-0 w-full h-full pointer-events-none z-20">
                <div className="absolute top-2 right-[5%] text-white/30 text-3xl animate-bounce delay-100">❄</div>
                <div className="absolute top-16 right-[15%] text-white/40 text-2xl animate-bounce delay-300">❄</div>
                <div className="absolute top-4 right-[25%] text-white/30 text-3xl animate-bounce delay-500">❄</div>
                <div className="absolute top-20 right-[35%] text-white/40 text-2xl animate-bounce delay-700">❄</div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ChristmasSale);
