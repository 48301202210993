import React, { Component } from "react";
import { connect } from "react-redux";
import VariableEditor from "./VariableEditor";
import { ClipboardIcon, FaceSmileIcon } from "@heroicons/react/20/solid";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { renderCustomEmojiCategories } from "../../../actions/index.js";

export class ToolTextArea extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showVariableEditor: false,
			emoji_show: false,
			cursorPosition: 0
		};
		this.emojiRef = React.createRef();
		this.textareaRef = React.createRef();
	}

	componentDidMount() {
		document.addEventListener("mousedown", this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
	}

	componentDidUpdate(prevProps) {
		if (this.props.value !== prevProps.value) {
			this.textareaRef.current.setSelectionRange(this.state.cursorPosition, this.state.cursorPosition);
		}
	}

	handleClickOutside = (event) => {
		if (this.emojiRef && this.emojiRef.current && !this.emojiRef.current.contains(event.target)) {
			this.setState({ emoji_show: false });
		}
	};

	handleInputChange = (e) => {
		const cursorPosition = e.target.selectionStart;
		this.props.onChange(e.target.value);
		this.setState({ cursorPosition });
	};

	onEmojiSelect = (emoji) => {
		let value = this.props.value || "";
		const cursorPosition = this.state.cursorPosition;
		var emojiString = "";
		if (!emoji.native) {
			const animatedCheck = emoji.src.includes(".gif");
			emojiString = `<${animatedCheck ? "a" : ""}:${emoji.name}:${emoji.id}>`;
			value = value.slice(0, cursorPosition) + emojiString + value.slice(cursorPosition);
		} else {
			value = value.slice(0, cursorPosition) + emoji.native + value.slice(cursorPosition);
		}
		this.props.onChange(value);
		this.setState({
			emoji_show: false,
			cursorPosition: cursorPosition + (emoji.native ? emoji.native.length : emojiString.length)
		});
	};

	variableEditorClick = (variable, slash) => {
		let value = this.props.value || "";
		const cursorPosition = this.state.cursorPosition;
		const insertText = slash ? `{option_${variable.name}}` : variable.variable;
		value = value.slice(0, cursorPosition) + insertText + value.slice(cursorPosition);
		this.props.onChange(value);
		this.setState({
			showVariableEditor: false,
			cursorPosition: cursorPosition + insertText.length
		});
	};

	renderEmojiContainer = () => {
		if (!this.state.emoji_show) return null;

		const customCategories = renderCustomEmojiCategories(this.props.bot_data);
		const categoryIcons = {};
		customCategories.forEach((category) => {
			categoryIcons[category.id] = {
				src: "https://www.svgrepo.com/show/353655/discord-icon.svg"
			};
		});

		return (
			<div ref={this.emojiRef} className="absolute z-[2001] top-full right-0">
				<Picker
					noCountryFlags={false}
					emojiVersion={14}
					data={data}
					custom={customCategories}
					categoryIcons={categoryIcons}
					onEmojiSelect={this.onEmojiSelect}
					title="Pick your emoji..."
				/>
			</div>
		);
	};

	render() {
		return (
			<div className="relative">
				<label htmlFor="email" className="block text-md font-bold leading-6 text-white">
					{this.props.label}{" "}
					{this.props.maxLength ? (
						<span className="muted-text opacity-50">
							{this.props.value.length}/{this.props.maxLength}
						</span>
					) : null}
				</label>
				<div className="relative mt-2">
					<textarea
						ref={this.textareaRef}
						type={this.props.type ? this.props.type : "text"}
						name="text"
						id="text"
						value={this.props.value}
						maxLength={this.props.maxLength ? this.props.maxLength : 2000}
						onChange={this.handleInputChange}
						className={`block w-full text-white resize-y ${this.props.bg ? this.props.bg : "bg-lightGray"}  rounded-md border-0 py-2 px-2 text-lg text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
					/>

					<div className="absolute top-2 right-2 flex items-center space-x-2">
						<ClipboardIcon
							onClick={(e) => {
								this.setState({ showVariableEditor: !this.state.showVariableEditor });
							}}
							className="text-white h-5 w-5 hover:opacity-50 hover:text-red cursor-pointer"
						/>
						{this.props.showEmojis !== false && (
							<FaceSmileIcon
								onClick={() => this.setState({ emoji_show: !this.state.emoji_show })}
								className="text-white h-5 w-5 hover:opacity-50 hover:text-red cursor-pointer"
							/>
						)}
					</div>
				</div>
				{this.renderEmojiContainer()}
				<div className="absolute top-[50px] left-0 min-w-[400px] w-full">
					{this.state.showVariableEditor && (
						<VariableEditor
							smallInput={true}
							slashcommand={true}
							slashcommandVariables={this.props.slash_options || []}
							announcement={false}
							click={this.variableEditorClick}
						/>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	bot_data: state.data.bot_data
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ToolTextArea);
