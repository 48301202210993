import React, { Component } from "react";
import { connect } from "react-redux";
import { getConnectedEdges, getOutgoers, addEdge, getIncomers, isEdge } from "react-flow-renderer";
import splitElements from "./splitElements";
import { setSelected, setElements, updateNode, setBlockTemplates, setFuture } from "../../../actions";
import update from "immutability-helper";
import { copyToast, successToast } from "./toast";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { BookmarkIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Select from "../elements/Select";
import LZString from "lz-string";
import server from "../../../api/server";


export class KeyEventsHandler extends Component {
	componentDidMount() {
		document.addEventListener("keydown", this.onKeyPress, false);
		document.addEventListener("mousemove", this.onMouseMove, false);
	}
	componentWillUnmount() {
		document.removeEventListener("keydown", this.onKeyPress, false);
		document.removeEventListener("mousemove", this.onMouseMove, false);
	}
	constructor(props) {
		super(props);

		this.state = {
			copiedBlock: null,
			template_description: "",
			template_name: "",
			edit_template_id: null,
			disableSaveTemplateButton: false,
			mousePosition: { x: 0, y: 0 },
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.elements.length != this.props.elements.length) {
			this.setState({
				past: {
					nodes: prevProps.elements,
					edges: splitElements(prevProps.elements).edges
				}
			});
		}
	}
	checkActionType = () => {
		var element = this.props.elements.find((element) => element.id == this.props.selected);
		if (element && element.data && element.data.data && element.type == "action") {
			return element.data.node_options.title;
		} else {
			return null;
		}
	};

	onMouseMove = (event) => {
		// console.log(event);
		this.setState({
			mousePosition: {
				x: event.clientX,
				y: event.clientY
			}
		});
	};

	multiDelete = (delElements) => {
		var elementsDel = [...delElements];
		// Remove all root, error_handler, else, loopChild
		elementsDel = elementsDel.filter((element) => element.id != "root" && element.id != "error_handler" && element.data?.data?.type != "else" && element?.type != "loopChild");
		elementsDel.forEach((element) => {

			if (element.type == "condition") {
				var elementChildren = getOutgoers(element, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);
				elementsDel = elementsDel.concat(elementChildren);
			} else if (element.data?.data?.type == "select_menu") {
				var elementChildren = getOutgoers(element, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);
				elementsDel = elementsDel.concat(elementChildren);
			} else if (element.data?.data?.type == "button_response") {
				var elementChildren = getOutgoers(element, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);
				elementsDel = elementsDel.concat(elementChildren);
			} else if (element.data?.data?.type == "loop") {
				var elementChildren = getOutgoers(element, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);
				elementsDel = elementsDel.concat(elementChildren);
			} else if (element.data?.data?.type == "advanced_message" && element.data?.data?.messageType == "advanced") {
				var elementChildren = getOutgoers(element, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);
				// For advanced message, only delete the button and menus that are connected;

				elementChildren = elementChildren.filter((child) => child?.type == "advMessageSelectMenu" || child?.type == "advMessageButton");

				elementsDel = elementsDel.concat(elementChildren);
			}
		});
		var connectedEdges = getConnectedEdges(elementsDel, splitElements(this.props.elements).edges);

		elementsDel = elementsDel.concat(connectedEdges);






		var elements = [...this.props.elements];
		var future_redo = [];
		elementsDel.forEach((eleDel) => {
			// Dont delete root or error handler
			if (eleDel.id == "root" || eleDel.id == "error_handler") {
				return;
			}
			var index = elements.findIndex((element) => element.id == eleDel.id);
			while (index != -1) {
				// Add to future
				future_redo.push(eleDel);
				elements.splice(index, 1);
				index = elements.findIndex((element) => element.id == eleDel.id);
			}
		});
		if (future_redo.length > 0) {
			var future = [...this.props.future];

			future.push(future_redo);

			this.props.setFuture(future);
		}
		this.props.setElements(elements);
		this.props.setSelected(null);

		setTimeout(() => {

			if (window.getSelection) {
				window.getSelection().removeAllRanges();
			} else if (document.selection) {
				document.selection.empty();
			}
			document.querySelector(".react-flow__pane").click();

		}, 100);
	};

	onKeyPress = (event) => {
		console.log(event);
		var element = this.props.elements.find((element) => element.id == this.props.selected);
		// if active element is an input or a textarea, return;

		// if copy, and selected is not null, copy the selected element

		// Redo (CTRL + Y)
		if ((event.ctrlKey || event.metaKey) && event.keyCode === 89) {
			if (document.activeElement.tagName === "INPUT" || document.activeElement.tagName === "TEXTAREA") {
				return;
			}
			var future = [...this.props.future];
			console.log('REDO', 'FUTURE');
			if (future.length > 0) {
				var redo = future.pop();
				var elements = [...this.props.elements];
				redo.forEach((element) => {
					elements.push(element);
				});
				this.props.setElements(elements);
				this.props.setFuture(future);
				this.props.setSelected(null);
			}
		}
		// Undo
		if ((event.ctrlKey || event.metaKey) && event.keyCode === 90 && !event.shiftKey) {
			if (document.activeElement.tagName === "INPUT" || document.activeElement.tagName === "TEXTAREA") {
				return;
			}
			this.props.setSelected(null);
			event.preventDefault();
			// Undo 1 step by deleting the last added element.
			var elements = [...this.props.elements];
			var undoElement = elements[elements.length - 1];
			if (isEdge(undoElement) && undoElement.type == 'step') {
				// get the target
				var target = elements.find((element) => element.id == undoElement.target);
				var source = elements.find((element) => element.id == undoElement.source);

				if (target.type == 'loopChild') {
					undoElement = source;
				}

				else if (source.type == 'option') {
					undoElement = source;
				}

				else if (target.type == 'button' || target.type == 'conditionChild' || target.type == 'selectMenuOption' || target.type == "advMessageButton" || target.type == "advMessageSelectMenu") {
					undoElement = target;
				}

				else if (target?.data?.data?.type == 'else') {
					undoElement = source;
				}



			}

			this.multiDelete([undoElement]);


		}

		// save
		// console.log(event);
		// event.preventDefault();
		if ((event.ctrlKey || event.metaKey) && event.keyCode === 83) {
			event.preventDefault();
			console.log("TRIGGER SAVE");
			this.props.triggerSave();
		}

		// Copy
		if ((event.ctrlKey || event.metaKey) && event.keyCode === 67 && this.props.selected != null) {
			// If focusing an input or textarea return;
			if (document.activeElement.tagName === "INPUT" || document.activeElement.tagName === "TEXTAREA") {
				return;
			}
			// Ignore these

			// this.props.selected != "root"
			// && this.props.selected != "error_handler"
			// && !(this.props.selected.includes("con")
			//     || this.props.selected.includes("option")
			//     || this.props.selected.includes("else")
			//     || (this.props.selected.includes("button") && !this.props.selected.includes("action"))
			//     || this.props.selected.includes("select")
			//     || this.checkActionType() == "Run a Loop"
			// this.setState({
			//     copiedBlock: element
			// });

			if (this.props.selected != "root" && this.props.selected != "error_handler" && !(this.props.selected.includes("con") || this.props.selected.includes("option") || this.props.selected.includes("else") || (this.props.selected.includes("button") && !this.props.selected.includes("action")) || this.props.selected.includes("select") || this.checkActionType() == "Run a Loop") && element.data.data.type != "loop") {
				this.setState({
					copiedBlock: element
				});

				// Toast

				copyToast("Block copied", "The block has been copied to the clipboard");
			}
		}
		// Paste
		else if ((event.ctrlKey || event.metaKey) && event.keyCode === 86 && this.state.copiedBlock != null) {
			if (document.activeElement.tagName === "INPUT" || document.activeElement.tagName === "TEXTAREA") {
				return;
			}

			var element = this.state.copiedBlock;
			var elements = [...this.props.elements];
			if (element.type == "action") {
				// Action
				var id = `action_${new Date().getTime()}_${Math.floor(Math.random() * 1000)}`;

				var data = {
					node_options: copy(element.data.node_options),
					data: copy(element.data.data)
				};

				// var data = {
				//     node_options: { ...element.data.node_options },
				//     data: { ...element.data.data }
				// };

				if (data.node_options.title == "Random Reply") {
					var responses = [];
					data.data.responses.forEach((response) => {
						responses.push({ ...response });
					});
					// data.data.responses = [...data.data.responses]
				}

				/**
				 * Here is if the duplicated actions need a unique ID
				 */
				if (data.node_options.title == "Create a Channel") {
					data.data.variable = `created_channel_${s4()}`;
				}

				if (data.node_options.title == "Create a Thread") {
					data.data.variable = `created_thread_${s4()}`;
				}

				if (data.node_options.title == "Edit a Thread") {
					data.data.variable = `edited_thread_${s4()}`;
				}
				const reactFlowBounds = this.props.reactFlowWrapper.current.getBoundingClientRect();

				console.log(reactFlowBounds, event.clientX);
				// Get mouse position

				// At Cursor
				const position = this.props.reactFlowInstance.project({
					x: this.state.mousePosition.x - reactFlowBounds.left,
					y: this.state.mousePosition.y - reactFlowBounds.top
				});
				console.log(position, "position");

				var newElement = {
					id: id,
					type: element.type,
					data: data,
					position: position
				};

				elements.push(newElement);

				this.props.setElements(elements);
				if (element.type == "api_action") {
					this.props.setSelected(id);
				} else {
					this.props.setSelected(null);
				}
			} else if (element.type == "option") {
				var id = `${new Date().getTime()}`;
				var data = {
					node_options: { ...element.data.node_options },
					data: { ...element.data.data }
				};
				const newElement = update(
					{},
					{
						id: id,
						type: element.type,
						data: data,
						position: {
							y: element.position.y,
							x: element.position.x + element.width + 25
						}
					}
				);
				elements.push(newElement);
				elements = addEdge(
					{
						source: id,
						target: "root",
						type: "step",
						animated: false,
						arrowHeadType: "arrowclosed"
					},
					elements
				);
				this.props.setElements(elements);
			}
		}

		// Delete multiple elements
		if ((event.keyCode === 46 || event.keyCode === 8) && document.activeElement.tagName !== "INPUT" && document.activeElement.tagName !== "TEXTAREA" && this.props.selected == null && this.props.selectedElements.nodes.length > 0) {
			this.multiDelete(this.props.selectedElements.nodes);
		}

		if ((event.keyCode === 46 || event.keyCode === 8) && document.activeElement.tagName !== "INPUT" && document.activeElement.tagName !== "TEXTAREA" && this.props.selected != null && ((element && element.data.data.type == "select_menu") || this.props.selected.includes("condition") || (element && element.data.data.type == "button_response") || element.data.data.type == "loop")) {
			var element = this.props.elements.find((element) => element.id == this.props.selected);
			var elementsDel = [element];
			this.multiDelete(elementsDel);
		} else if ((event.keyCode === 46 || event.keyCode === 8) && document.activeElement.tagName !== "INPUT" && document.activeElement.tagName !== "TEXTAREA" && this.props.selected != null && (this.props.selected.includes("else") || this.props.selected.includes("loop_actions"))) {
			// Can't delete else statement for conditions;
		} else if ((event.keyCode === 46 || event.keyCode === 8) && document.activeElement.tagName !== "INPUT" && document.activeElement.tagName !== "TEXTAREA" && this.props.selected != null && this.props.selected != "root" && this.props.selected != "error_handler") {
			var elementsDel = [this.props.elements.find((element) => element.id == this.props.selected)];
			this.multiDelete(elementsDel);
		}
		// else if (event.keyCode == 46 && this.props.selected == null && this.state.selectedElements.nodes.length > 0) {
		//   this.deleteMulti();
		// }
	};

	createTemplate = async () => {
		this.setState({
			disableSaveTemplateButton: true
		});
		var selection = { ...this.props.selectedElements };
		var newElements = [];
		var newEdges = [];
		var nodes = [...selection.nodes];
		var edges = [...selection.edges];
		for (var i = 0; i < nodes.length; i++) {
			var node = nodes[i];
			// nodes.forEach(node => {

			if (node.id == "root" || node.id == "error_handler" || node.type == "option") {
				continue;
			}

			if (node.type == "button" || node.type == "conditionChild" || node.type == "selectMenuOption") {
				// Get the node parent and add it to.
				var allEdges = splitElements(this.props.elements).edges;
				var parent = getIncomers(node, splitElements(this.props.elements).nodes, allEdges);
				if (parent) {
					var parentIndex = nodes.findIndex((n) => n.id == parent[0].id);
					if (parentIndex == -1) {
						nodes.push(parent[0]);
					}
				}
			} else if (node.type == "condition") {
				var allEdges = splitElements(this.props.elements).edges;
				var elementChildren = getOutgoers(node, splitElements(this.props.elements).nodes, allEdges);
				if (elementChildren) {
					var elseChild = elementChildren.find((c) => c.data.data.type == "else");
					if (elseChild) {
						var elseChildIndex = nodes.findIndex((n) => n.id == elseChild.id);
						if (elseChildIndex == -1) {
							var newEdge = {
								id: `edge_${s4()}_${s4()}_${s4()}`,
								source: node.id,
								target: elseChild.id,
								type: "smoothstep",
								animated: false,
								arrowHeadType: "arrowclosed",
								style: { stroke: "#fff" }
							};
							nodes.push(elseChild);
							edges.push(newEdge);
						}
					}
				}
			} else if (node.type == "loopChild") {
				var allEdges = splitElements(this.props.elements).edges;
				var parent = getIncomers(node, splitElements(this.props.elements).nodes, allEdges);
				if (parent) {
					var parentIndex = nodes.findIndex((n) => n.id == parent[0].id);
					if (parentIndex == -1) {
						nodes.push(parent[0]);
					}
				}
			} else if (node.type == "action" && node.data.data.type == "loop") {
				// Clone children
				var allEdges = splitElements(this.props.elements).edges;
				var elementChildren = getOutgoers(node, splitElements(this.props.elements).nodes, allEdges);
				if (elementChildren) {
					elementChildren.forEach((child) => {
						var childIndex = nodes.findIndex((n) => n.id == child.id);
						if (childIndex == -1) {
							nodes.push(child);
						}
					});
				}
			}
			var newNode = copy(node);
			var id_type = "action";
			if (node.type == "condition") {
				id_type = "condition";
			} else if (node.type == "button") {
				id_type = "button";
			} else if (node.type == "selectMenuOption") {
				id_type = "select_menu_option";
			} else if ("data" in node && "data" in node.data && "type" in node.data.data && node.data.data.type == "else") {
				id_type = "else";
			} else if (node.type == "conditionChild") {
				id_type = "conChild";
			}

			var id = `${id_type}_${new Date().getTime()}_${s4()}_${s4()}`;
			newNode.oldId = newNode.id;
			newNode.id = id;
			newNode.position.x += 500;

			newElements.push(newNode);
			if (newNode.type == "option") {
				var newEdge = {
					id: `edge_${s4()}_${s4()}_${s4()}`,
					source: newNode.id,
					target: "root",
					type: "smoothstep",
					animated: false,
					arrowHeadType: "arrowclosed",
					style: { stroke: "#fff" }
				};
				newElements.push(newEdge);
			}
			// });
		}
		var elements = [...newElements];

		edges.forEach((edge, index) => {
			if (edge.source == "root") {
				// edges.splice(index, 1);
			} else {
				var source = newElements.find((element) => element.oldId == edge.source);
				var target = newElements.find((element) => element.oldId == edge.target);

				if (source && target) {
					var newEdge = copy(edge);
					// delete newEdge.id;
					newEdge.id = `edge_${new Date().getTime()}_${s4()}_${s4()}`;
					newEdge.source = source.id;
					newEdge.target = target.id;

					elements.push(newEdge);
				}
			}
		});

		console.log("newElements", elements);
		var template = {
			name: this.state.template_name,
			description: this.state.template_description,
			id: `template_${new Date().getTime()}_${s4()}`,
			elements_string: ""
		};

		var tree_string = JSON.stringify(elements);
		var compressed_tree_string = LZString.compressToBase64(tree_string);
		template.elements_string = compressed_tree_string;
		console.log(template, "TEMPLATE");

		var response = await server.post("/dashboard/block_template/create", {
			template: template,
			edit_template_id: this.state.edit_template_id
		});

		if (response && response.data && response.data.success) {
			this.props.setBlockTemplates(response.data.templates);
			this.props.closeSaveTemplateModal();
			setTimeout(() => {

				if (window.getSelection) {
					window.getSelection().removeAllRanges();
				} else if (document.selection) {
					document.selection.empty();
				}
				document.querySelector(".react-flow__pane").click();

			}, 1000);
			// Reset state
			this.setState({
				template_name: "",
				template_description: "",
				edit_template_id: null,
				disableSaveTemplateButton: false
			});
		} else {
			this.setState({ disableSaveTemplateButton: false });
		}
	};

	renderOptions = () => {
		var options = [{ value: "", label: "" }];
		this.props.block_templates.forEach((template) => {
			options.push({ value: template.id, label: template.name });
		});
		return options;
	};
	render() {
		return (
			<>
				<Transition.Root show={this.props.showSaveTemplateModal} as={Fragment}>
					<Dialog
						as="div"
						className="relative z-10"
						onClose={() => {
							this.props.closeSaveTemplateModal();
							// this.setState({ openModal: false });
						}}
					>
						<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
							<div className="fixed inset-0 bg-[#0000008a] transition-opacity" />
						</Transition.Child>

						<div className="fixed inset-0 z-10 overflow-y-auto">
							<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
								<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
									<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-lightGray px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
										<div className="sm:flex sm:items-start mb-2">
											{/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red sm:mx-0 sm:h-10 sm:w-10">
												<BookmarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
											</div> */}
											<div className="text-left">
												<Dialog.Title as="h3" className="text-xl text-white font-bold leading-6">
													Save Block Template
												</Dialog.Title>
												<div className="mt-2">
													<p className="text-muted mb-3 font-semibold">You are about to save these blocks as a template. This will allow you to reuse this block in other commands and events.</p>
												</div>
											</div>
										</div>

										<div className="flex flex-col gap-y-3">
											{/* Save over existing template select */}
											<div className=" font-semibold">
												<h4 className="text-white font-bold">Edit Existing Template</h4>
												<span className="text-muted">Optionally edit and overwrite an existing block template. </span>
												<Select
													value={this.state.edit_template_id}
													options={this.renderOptions()}
													onChange={(value) => {
														// Set name, description and edit_template_id;
														var template = this.props.block_templates.find((template) => template.id == value);
														this.setState({ edit_template_id: value, template_name: template.name, template_description: template.description });
													}}
												></Select>
											</div>

											{/* Name */}
											<hr className="slash-hr" style={{ borderTop: "1px solid #adb5bd" }}></hr>

											<div className="font-semibold flex flex-col">
												<h4 className="text-white font-bold">Template Name</h4>
												<span className="text-muted mb-2">A descriptive name for this template</span>
												<div className="long-input relative">
													<label>{"Name"}</label>
													<input
														maxLength={100}
														placeholder={"My Template"}
														className={``}
														required
														type="text"
														value={this.state.template_name}
														onChange={(e) => {
															// this.props.onChange(e.target.value);
															this.setState({ template_name: e.target.value });
														}}
													></input>
												</div>
											</div>

											{/* Description */}

											<div className="font-semibold flex flex-col">
												<h4 className="text-white font-bold">Template Description</h4>
												<span className="text-muted mb-2">A description for this template</span>
												<div className="long-input relative">
													<label>{"Description"}</label>
													<input
														maxLength={255}
														placeholder={"My template is great"}
														className={``}
														required
														type="text"
														value={this.state.template_description}
														onChange={(e) => {
															// this.props.onChange(e.target.value);
															this.setState({ template_description: e.target.value });
														}}
													></input>
												</div>
											</div>
										</div>

										<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
											<button
												type="button"
												disabled={this.state.template_name.length == 0 || this.state.template_name.length > 100 || this.state.template_description.length == 0 || this.state.template_description.length > 255 || this.state.disableSaveTemplateButton}
												className="inline-flex w-full justify-center rounded-md border border-transparent bg-red px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
												onClick={() => {
													// this.handleImport();
													this.createTemplate();
													// this.setState({ openModal: false });
												}}
											>
												Save Template
											</button>
											<button
												type="button"
												onClick={() => {
													this.props.closeSaveTemplateModal();
												}}
												className=" inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
											// onClick={() => this.setState({ openModal: false })}
											>
												Cancel
											</button>

											{/* <a type="button" target="_blank" href={`https://botghost.com/market/${this.props.mode}/${this.props.item.market_id}`} className="mr-auto inline-flex w-full justify-center rounded-md border border-transparent bg-red px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm hover:no-underline hover:opacity-80">
												View {this.props.mode == "event" ? "Event" : "Command"} Page
											</a> */}
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</Dialog>
				</Transition.Root>
				{this.props.children}
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	elements: state.builder.elements,
	selected: state.builder.selected,
	index: state.builder.index,
	hidden: state.builder.hidden,
	highlightAction: state.builder.highlightAction,
	mode: state.builder.mode,
	module_id: state.builder.module_id,
	premium: state.data.premium,
	future: state.builder.future,
	block_templates: state.data.user.block_templates ? state.data.user.block_templates : []
});

const mapDispatchToProps = {
	setElements,
	setSelected,
	updateNode,
	setBlockTemplates,
	setFuture
};

function copy(obj) {
	// Get object type
	let type = Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();

	/**
	 * Create an immutable copy of an object
	 * @return {Object}
	 */
	function cloneObj() {
		let clone = {};
		for (let key in obj) {
			if (obj.hasOwnProperty(key)) {
				clone[key] = copy(obj[key]);
			}
		}
		return clone;
	}

	/**
	 * Create an immutable copy of an array
	 * @return {Array}
	 */
	function cloneArr() {
		return obj.map(function (item) {
			return copy(item);
		});
	}

	// Return a clone based on the object type
	if (type === "object") return cloneObj();
	if (type === "array") return cloneArr();
	return obj;
}

let s4 = () => {
	return Math.floor((1 + Math.random()) * 0x10000)
		.toString(16)
		.substring(1);
};

export default connect(mapStateToProps, mapDispatchToProps)(KeyEventsHandler);
