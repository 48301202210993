import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import history from "../../../history";

export class PremiumRequiredModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: true
        };
    }

    render() {
        return (
            <Transition appear show={this.state.open} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-[9999]"
                    onClose={() => { this.props.close(); this.setState({ open: false }); }}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-[#1a1c1f]/90 z-[9999]" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto z-[9999]">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-lg bg-[#2b2d31] p-8 text-left align-middle shadow-xl transition-all border border-[#1a1c1f]">
                                    <div className="flex justify-center mb-6">
                                        <img
                                            src="/images/logo-red.png"
                                            alt="BotPanel Logo"
                                            className="w-12 h-12"
                                        />
                                    </div>
                                    <Dialog.Title
                                        as="h3"
                                        className="text-2xl font-bold text-center text-white"
                                    >
                                        Premium Required
                                    </Dialog.Title>
                                    <div className="mt-4">
                                        <p className="text-[#b5bac1] text-center text-base leading-relaxed">
                                            {
                                                this.props.premiumMessage ? this.props.premiumMessage : "This feature is only available to premium users."
                                            }
                                        </p>
                                    </div>

                                    <div className="mt-8 flex flex-col sm:flex-row gap-3 justify-center">
                                        <button
                                            onClick={() => {
                                                history.push("/dashboard/premium");
                                                this.setState({ open: false });
                                            }}
                                            className="inline-flex justify-center items-center rounded-md bg-[#f0b232] hover:bg-[#ffbd3e] px-8 py-2.5 text-sm font-bold text-white transition-all duration-200 transform hover:-translate-y-0.5 hover:shadow-lg"
                                        >
                                            Get Premium
                                        </button>
                                        <button
                                            onClick={() => { this.props.close(); this.setState({ open: false }); }}
                                            className="inline-flex justify-center items-center rounded-md bg-[#2f3136] px-8 py-2.5 text-sm font-medium text-white hover:bg-[#383a40] transition-colors duration-200"
                                        >
                                            Close
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        );
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(PremiumRequiredModal);