
import server from '../api/server';
import history from "../history";
import * as Sentry from "@sentry/react";
import ReactGA from 'react-ga';
import { faDoorClosed } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
export const updatePage = (page) => {
    return {
        type: "SET_PAGE",
        payload: page
    };
};

export const renderCustomEmojiCategories = (botData) => {
    var categories = [];
    if (!botData) {
        return categories;
    }

    botData.forEach(guild => {
        var emojis = guild.emojis;
        if (emojis.length > 0) {
            var category = [];
            emojis.forEach(emoji => {
                var src = `https://cdn.discordapp.com/emojis/${emoji.id}.png`;
                if (emoji.animated) {
                    src = `https://cdn.discordapp.com/emojis/${emoji.id}.gif`;
                }
                category.push(
                    {
                        id: emoji.id,
                        name: emoji.name,
                        keywords: [emoji.name, guild.id, guild.name],
                        frequent: true,
                        native: emoji.name,
                        skins: [{ src: src }],
                    }
                );
            });

            categories.push({
                id: guild.id,
                name: guild.name,
                emojis: category

            });
        }
    });

    // console.log(categories, 'CATEGORIES');

    return categories;
};

export const updateInactiveBot = (value) => {
    return {
        type: "UPDATE_INACTIVE_BOT",
        payload: value
    };
};

export const removeServerLimitError = (bot_id) => {
    return {
        type: "REMOVE_SERVER_LIMIT_ERROR",
        payload: bot_id
    };
};

export const setBlockTemplates = (templates) => {
    return {
        type: "SET_BLOCK_TEMPLATES",
        payload: templates
    };
};
export const setAuth = (params) => {
    return async (dispatch, getState) => {
        // Set redirect URL to 
        var landing_page = Cookies.get('landing_page');
        console.log(landing_page, 'LANDING PAGE');
        const response = await server.get(`/auth`).catch(e => {

        });

        if (response != undefined && response.data.success === true) {
            dispatch({
                type: "SET_AUTH",
                payload: true
            });
        } else {
            console.log(landing_page, 'LANDING PAGE2');
            console.log(process.env.REACT_APP_API_SERVER + "/discord?redirect=" + window.location.href + "&landing_page=" + landing_page);
            if (landing_page) {
                window.location.replace(process.env.REACT_APP_API_SERVER + "/discord?redirect=" + window.location.href + "&landing_page=" + landing_page);
            } else {
                window.location.replace(process.env.REACT_APP_API_SERVER + "/discord?redirect=" + window.location.href);
            }
        }
    };
};


export const setGroupId = (groupId) => {
    return ({
        type: "SET_GROUP_ID",
        payload: groupId
    });
};

export const refreshIfttt = (status) => {
    return ({
        type: "IFTTT_LINK_UPDATE",
        payload: status
    });
};

export const getData = () => {
    return async (dispatch, getState) => {
        const response = await server.get("/dashboard/data").catch(e => {
            // console.log(e);
        });
        // console.log(response.data);
        if (response == undefined) {
            window.location.replace("/dashboard");
        } else if (response.data.success === true) {
            if (response.data.botData.bot != undefined) {

                response.data.botData.premium = response.data.botData.bot.premium;
                const bot = newFeatures(response.data.botData.bot);
                response.data.botData.bot = bot;
                Sentry.setContext("user", {
                    email: response.data.botData.user.email,
                });

                Sentry.setContext("bot", {
                    bot: response.data.botData.bot,
                });

                dispatch({
                    type: "SET_DATA",
                    payload: response.data.botData
                });
                if (response.data.botData.user.quick_start != undefined) {
                    dispatch({
                        type: "SET_QUICK_START",
                        payload: response.data.botData.user.quick_start
                    });
                }

                // if (response.data.botData.cocreate) {
                //     dispatch({
                //         type: "SET_COCREATE",
                //         payload: response.data.botData.cocreate
                //     });
                // }

                if (response.data.botData.collab) {
                    dispatch({
                        type: "SET_COLLAB",
                        payload: true
                    });
                }
                dispatch(getBots());
                // dispatch(fetchBotData())
                dispatch(getNotifications());
            } else {
                history.push("/dashboard/new");
            }
        } else if (response.data.success == false && response.data.reason == 'auth') {
            dispatch({
                type: "SET_AUTH",
                payload: false
            });
            var landing_page = Cookies.get('landing_page');
            if (landing_page) {
                window.location.replace(process.env.REACT_APP_API_SERVER + "/discord?redirect=" + window.location.href + "&landing_page=" + landing_page);
            } else {
                window.location.replace(process.env.REACT_APP_API_SERVER + "/discord");
            }

        }
    };
};

export const updateErrorLogs = (logs) => {
    return ({
        type: "UPDATE_ERROR_LOGS",
        payload: logs
    });

};

export const updateErrorLogsChecked = (time) => {
    return ({
        type: "UPDATE_ERRORLOGS_CHECKED",
        payload: time
    });

};

export const setQuickStart = (quick_start) => {
    return async (dispatch) => {
        ReactGA.initialize('UA-122665050-1');
        ReactGA.event({
            category: "Quick Start",
            label: `Completed`,
            action: "New Dashboard"
        });
        const response = await server.post("/dashboard/quickstart", { quick_start }).catch(e => {

        });
        if (response != undefined && response.data == true) {
            dispatch({
                type: "SET_QUICK_START",
                payload: quick_start
            });
        } else {
            dispatch(setAuth());
        }
    };
};

export const getBots = () => {
    return async (dispatch, getState) => {
        const response = await server.get("/dashboard/bots").catch(e => {

        });
        if (response != undefined && response.data && response.data.success != false) {
            dispatch({
                type: "SET_BOTS",
                payload: response.data.bots
            });
        } else if (response.data && response.data.success == false && response.data.reason == "logged") {
            dispatch(setAuth());
        }
    };
};

export const fetchBotData = () => {
    return async (dispatch, getState) => {
        const { id, token } = getState().data.bot;
        const response = await server.post("/dashboard/botData", {
            id: id,
            token: token
        }).catch(e => {

        });

        if (response != undefined && response.data.success === true) {
            const botData = response.data.bot_data;
            dispatch({
                type: "SET_BOT_DATA",
                payload: botData
            });
        } else {
            window.location.replace("/dashboard");
        }
    };
};

export const setBotData = (bot_data) => {
    return ({
        type: "SET_BOT_DATA",
        payload: bot_data
    });
};

export const setHighlightAction = (id) => {
    return ({
        type: "SET_HIGHLIGHT_ACTION",
        payload: id
    });
};

export const updateCommandOptions = (module, command, options) => {
    // console.log(module, command, options);
    return ({
        type: "UPDATE_COMMAND_OPTIONS",
        payload: {
            module,
            command,
            options
        }
    });
};

export const updateCustomCommandOptions = (options, index) => {

    return async (dispatch, getState) => {
        const commands = getState().data.bot.commands.autoresponder;
        Object.keys(options).forEach(key => {
            commands[index][key] = options[key];
        });

        dispatch({
            type: "UPDATE_CUSTOM_COMMAND_OPTIONS",
            payload: commands
        });


    };
    // // console.log(module,command,options)
    // console.log(options,index)
    // return({
    //     type:"UPDATE_CUSTOM_COMMAND_OPTIONS",
    //     payload:{
    //         options,
    //         index
    //     }
    // })
};

export const cleanUpActionTrees = (bot) => {
    // Check bot size in bytes
    var size = new TextEncoder().encode(JSON.stringify(bot)).length;
    console.log(size, 'BEFORE');
    if (bot.commands.slash.commands.length > 0) {

        bot.commands.slash.commands.forEach(command => {
            if (command?.compressed_tree && command) {
                delete command.compressed_actions;
                delete command.actions;
            }

            if (!command?.int_id && command) {
                command.int_id = uuidv4();
            }
        });
    }



    if (bot.commands.custom_events.events.length > 0) {

        bot.commands.custom_events.events.forEach(event => {

            if (event?.compressed_tree && event) {
                delete event.compressed_actions;
                delete event.actions;
            }

            if (!event?.int_id && event) {
                event.int_id = uuidv4();
            }
        });
    }


    if (bot.commands.moduleSettings) {
        Object.keys(bot.commands.moduleSettings).forEach(key => {
            if (bot.commands.moduleSettings[key].commands.length > 0) {
                bot.commands.moduleSettings[key].commands.forEach(command => {
                    // If command is null or undefined, skip
                    if (command?.compressed_tree) {
                        delete command.compressed_actions;
                        delete command.actions;
                    }

                    if (!command?.int_id && command) {
                        command.int_id = uuidv4();
                    }

                });
            }
            if (bot.commands.moduleSettings[key].events.length > 0) {
                bot.commands.moduleSettings[key].events.forEach(event => {
                    if (event?.compressed_tree) {
                        delete event.compressed_actions;
                        delete event.actions;
                    }

                    if (!event?.int_id && event) {
                        event.int_id = uuidv4();
                    }
                });
            }


            // Go through settings

            Object.keys(bot.commands.moduleSettings[key].settings).forEach(setting => {
                if (bot.commands.moduleSettings[key].settings[setting].type == "slot") {
                    bot.commands.moduleSettings[key].settings[setting].value.forEach(value => {
                        delete value.event;
                    });
                }
            });

        });
    }

    var afterSize = new TextEncoder().encode(JSON.stringify(bot)).length;

    console.log(afterSize, 'AFTER');

    return bot;

    // Go through commands, events and moduleSetting commands and events and delete command_tree
    //         delete object.actions;
    // delete object.compressed_actions;
};

export const upgradeSubscription = (data) => {
    return async (dispatch, getState) => {
        dispatch({
            type: "UPGRADE_SUBSCRIPTION",
            payload: data
        });

        history.push("/dashboard/settings");
    };
};

export const saveBot = (pre_save) => {
    return ({
        type: "SAVE_BOT",
        payload: pre_save
    });
};

export const changeBot = (id, collab, host_user_id, beta) => {
    return async (dispatch, getState) => {
        const response = await server.post("/dashboard/changebot", { id: id, collab: collab ? true : false, host_user_id: host_user_id })
            .catch(e => {

            });
        if (response != undefined && response.data && response.data.success != false) {
            if (!('server_members_intent' in response.data)) {
                response.data.server_members_intent = false;
            }

            if (response.data.api_errors == undefined) {
                response.data.api_errors = [];
            }

            const bot = newFeatures(response.data.bot);
            response.data.bot = bot;
            dispatch({
                type: "CHANGE_BOT",
                payload: { data: response.data, collab: collab ? true : false, beta: beta }
            });
        } else if (response == undefined || response.data && response.data.success == false && response.data.reason == "logged") {
            dispatch(setAuth());
        }
    };
};

export const updateBeta = (value) => {
    return ({
        type: "UPDATE_BETA",
        payload: value
    });
};

export const upgradeBot = (data) => {
    return async (dispatch, getState) => {
        dispatch({
            type: "UPGRADE_BOT",
            payload: data
        });
    };
};

export const updateProcessing = (value) => {
    return ({
        type: "UPDATE_PROCESSING",
        payload: value
    });
};

export const updateServerPopup = () => {
    return ({
        type: "UPDATE_SERVER_POPUP",
        payload: true
    });
};

export const updateSubmoduleCommandOptions = (module, submodule, command, options) => {

    return ({
        type: "UPDATE_SUBMODULE_COMMAND_OPTIONS",
        payload: {
            module,
            submodule,
            command,
            options
        }
    });
};

export const setBotSettings = (data) => {
    return ({
        type: "SET_BOT_SETTINGS",
        payload: data
    });
};

export const setBotModule = (data) => {
    return ({
        type: "SET_BOT_MODULE",
        payload: data
    });
};

export const revertChanges = (pre_save) => {
    return ({
        type: "REVERT_CHANGES",
        payload: pre_save
    });
};

export const changeName = (name) => {
    return ({
        type: "CHANGE_NAME",
        payload: name
    });
};

export const changeIMG = (image) => {
    return ({
        type: "CHANGE_IMAGE",
        payload: image
    });
};


export const setOnlineStatus = (status) => {
    return async (dispatch, getState) => {
        dispatch({
            type: "SET_ONLINE_STATUS",
            payload: status
        });
    };
};


export const getNotifications = () => {
    return async (dispatch, getState) => {
        const response = await server.get("/dashboard/notifications").catch(e => {

        });
        if (response != undefined && response.data) {
            dispatch({
                type: "SET_NOTIFICATIONS",
                payload: response.data.notifications
            });
        }
    };
};

export const setServerIntents = (value) => {
    return ({
        type: "SET_SERVER_INTENTS",
        payload: value
    });
};


// BUILDER ACTIONS
export const setElements = (elements) => {
    return ({
        type: "SET_ELEMENTS",
        payload: elements
    });
};

export const setFuture = (newFuture) => {
    return (dispatch, getState) => {
        console.log("SET FUTURE", newFuture);
        // If the future array is over 100 elements, remove the first element
        if (newFuture.length > 100) {
            newFuture.shift();
        }



        dispatch({
            type: "SET_FUTURE",
            payload: newFuture
        });
    };
};

export const updateNode = (node) => {
    return ({
        type: "UPDATE_NODE",
        payload: node
    });
};

export const hideAside = (value) => {
    return ({
        type: "ASIDE_VISIBLE_TOGGLE",
        payload: value
    });
};

export const setBuilderIndex = (index) => {
    return ({
        type: "SET_BUILDER_INDEX",
        payload: index
    });
};

export const setWebhookId = (ID) => {
    return ({
        type: "SET_WEBHOOK_ID",
        payload: ID
    });
};

export const setFavModules = (modules) => {
    return ({
        type: "SET_FAV_MODULES",
        payload: modules
    });
};

export const setBuilderModuleId = (id) => {
    return ({
        type: "SET_MODULE_ID",
        payload: id
    });
};

export const setBuilderSlotId = (id) => {
    return ({
        type: "SET_SLOT_ID",
        payload: id
    });
};
export const setBuilderMarketID = (id) => {
    return ({
        type: "SET_MARKET_ID",
        payload: id
    });
};


export const resetBuilder = () => {
    return ({
        type: "RESET_BUILDER",

    });
};
export const setRootData = (data) => {

    return ({
        type: "SET_ROOT_DATA",
        payload: data
    });
};

export const setSelected = (id) => {
    return ({
        type: "SET_SELECTED",
        payload: id
    });
};

export const setBuilderMode = (data) => {
    return ({
        type: "SET_BUILDER_MODE",
        payload: data
    });
};

export const updateElementData = (data, getState) => {
    // Check the element still exists;
    // const element = getState().builder.elements.find(element => element.id === data.id);
    // if (element) {
    return ({
        type: "UPDATE_ELEMENT_DATA",
        payload: data
    });
    // }
};

export const updateEmptyBlacklist = (value) => {
    return ({
        type: "UPDATE_EMPTY_BLACKLIST",
        payload: value
    });
};

export const setCommandSaveFailed = (value) => {
    return ({
        type: "COMMAND_BUILDER_SAVE_FAILED",
        payload: value
    });
};

export const setNotifications = (notifications) => {
    return async (dispatch) => {
        // console.log(notifications);
        const response = await server.post("/dashboard/notifications", { notifications: notifications })
            .catch(e => {

            });

        if (response != undefined && response.data == true) {
            dispatch({
                type: "UPDATE_NOTIFICATIONS",
                payload: notifications
            });
        } else if (response == undefined || response.data == false) {
            dispatch(setAuth());
        }

    };
};

export const updateModals = (modals) => {
    return async (dispatch) => {
        const response = server.post("/dashboard/updateModals", { modals: modals })
            .catch(e => {

            });
        dispatch({
            type: "UPDATE_MODALS",
            payload: modals
        });


    };
};

export const setUsage = (usage) => {
    return async (dispatch) => {

        dispatch({
            type: "UPDATE_USAGE",
            payload: usage
        });


    };
};

const newFeatures = (bot) => {

    var timezone = moment.tz.guess();

    if (!("ifttt" in bot.commands)) {
        bot.commands.ifttt = {
            enabled: true,
            events: []
        };
    }
    if (!("timed_events" in bot.commands)) {
        bot.commands.timed_events = {
            timezone: timezone,
            timezone_default: true,
            events: [
                // {
                // 	id: "123456",
                // 	name: "Test Event",
                // 	type: "schedule",
                // 	time: {
                // 		schedules: ["14:15:00", "14:16:00", "14:17:00", "14:31:00"]
                // 	},
                // 	days: {
                // 		mon: true,
                // 		tue: true,
                // 		wed: true,
                // 		thu: true,
                // 		fri: true,
                // 		sat: true,
                // 		sun: true
                // 	}
                // }
            ]
        };
    }

    if (!("moduleSettings" in bot.commands)) {
        bot.commands.moduleSettings = {

        };
    }
    if (!("activeDevBadge" in bot.commands)) {
        bot.commands.activeDevBadge = {
            enabled: false
        };
    }
    if (!("custom_events" in bot.commands)) {
        bot.commands.custom_events = {
            events: []
        };
    }
    if (!("variables" in bot.commands)) {
        bot.commands.variables = {
            variables: [

            ]
        };
    }

    if (!("webhooks" in bot.commands)) {
        bot.commands.webhooks = {
            enabled: true,
            webhooks: []
        };
    }

    if (!("tickets" in bot.commands)) {
        bot.commands.tickets = {
            enabled: false,
            trigger: "tickets", // trigger for the main command
            adminTrigger: "tickets-admin",
            options: { // holds all the options for the ticket system, https://sherlock.sloththe.dev/files/MongoDBCompass_pocs89NyDC.png
                pingRoles: false, // whether to ping roles or not upon ticket creation
                roles: [], // roles you want to be given access to the ticket
                // {
                // 	name: "",
                // 	id: ""
                // }
                created_message: "Thank you for creating a ticket!",
                category: {
                    // category_id:"998128950721126420"
                }, // category
                // {
                // 	name: "",
                // 	category_id: "",
                // 	open_message: ""
                // }
                logEvents: false, // log events to ticket log channel
                logChannel: {},
                transcript: true,
                categories: [
                    // {
                    //     name:"Sales",
                    //     category_id:"998229212018253825",
                    //     roles:[]
                    // }
                ],
                // {
                // 	name: "Sales",
                // 	category_id: "",
                //	roles: []
                // }
                modal: {
                    title: "Log a Ticket",
                    description: "Why are you logging a ticket"
                }
            },
            create: {
                enabled: true,
                permissions: { allowed_roles: [{ name: "@everyone", id: "everyone" }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: [] }
            },
            close: {
                enabled: true,
                permissions: { allowed_roles: [{ name: "@everyone", id: "everyone" }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: [] }
            },
            addUser: {
                enabled: true,
                permissions: { allowed_roles: [{ name: "@everyone", id: "everyone" }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: [] }
            },
            removeUser: {
                enabled: true,
                permissions: { allowed_roles: [{ name: "@everyone", id: "everyone" }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: [] }
            },
            createPanel: {
                enabled: true,
                permissions: { allowed_roles: [{ name: "@everyone", id: "everyone" }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: ["ADMINISTRATOR"] }
            },
        };
    }

    if ("tickets" in bot.commands && !("blacklistUsers" in bot.commands.tickets.options)) {
        // console.log("ELSE IF HIT");
        bot.commands.tickets.options.blacklistUsers = [];
        bot.commands.tickets.options.blacklistRoles = [];
        bot.commands.tickets.addBlacklist = {
            enabled: true,
            permissions: { allowed_roles: [{ name: "@everyone", id: "everyone" }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: ["ADMINISTRATOR"] }
        };

        bot.commands.tickets.removeBlacklist = {
            enabled: true,
            permissions: { allowed_roles: [{ name: "@everyone", id: "everyone" }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: ["ADMINISTRATOR"] }

        };
    }

    if (!('settings' in bot.commands)) {
        bot.commands.settings = {
            delete: {
                enabled: false
            },
            mention: {
                enabled: false
            }
        };
    }




    if (!("embeds" in bot.commands)) {
        bot.commands.embeds = {
            enabled: false,
            embeds: []
        };
    }

    if (!("opensea" in bot.commands)) {
        bot.commands.opensea = {
            enabled: false,
            collection_slug: "",
            opensea: {
                trigger: "opensea",
            },
            item: {
                enabled: true,
                permissions: { allowed_roles: [{ name: "@everyone", id: "everyone" }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: [] }
            },
            collection: {
                enabled: true,
                permissions: { allowed_roles: [{ name: "@everyone", id: "everyone" }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: [] }
            },
            floor: {
                enabled: true,
                permissions: { allowed_roles: [{ name: "@everyone", id: "everyone" }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: [] }
            },
        };
    }
    if (!("opensea_alerts" in bot.commands)) {
        bot.commands.opensea_alerts = {
            enabled: false,
            alerts: [
            ]
        };
    }

    if (!("crypto" in bot.commands)) {
        bot.commands.crypto = {
            enabled: false,
            price: {
                enabled: true,
                permissions: { allowed_roles: [{ name: "@everyone", id: "everyone" }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: [] }
            }
        };
    }
    if (!("giveaways" in bot.commands)) {
        bot.commands.giveaways = {
            "enabled": false,
            buttons: true,
            buttonText: "",
            giveaway: {
                "permissions": {
                    "allowed_roles": [{
                        "name": "@everyone",
                        "id": "everyone"
                    }],
                    "banned_users": [],
                    "banned_roles": [],
                    "banned_channels": [],
                    "required_permissions": ["ADMINISTRATOR"]
                }
            }
        };
    }
    if (!("dmsong" in bot.commands.music)) {
        bot.commands.music.dmsong = {
            "permissions": {
                "allowed_roles": [{
                    "name": "@everyone",
                    "id": "everyone"
                }],
                "banned_users": [],
                "banned_roles": [],
                "banned_channels": [],
                "required_permissions": []
            }
        };

        bot.commands.music.filter = {
            "permissions": {
                "allowed_roles": [{
                    "name": "@everyone",
                    "id": "everyone"
                }],
                "banned_users": [],
                "banned_roles": [],
                "banned_channels": [],
                "required_permissions": []
            }
        };
    }
    if (!("logging" in bot.commands) || !("messageDelete" in bot.commands.logging)) {
        if (!("logging" in bot.commands)) {
            bot.commands.logging = {

            };
        }
        bot.commands.logging.messageDelete = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.messageUpdate = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.messagePinned = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.guildUpdate = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.guildMemberJoin = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.guildMemberRemove = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.guildMemberUpdate = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.guildEventCreate = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.guildEventDelete = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.guildEventUpdate = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.memberRoleAdd = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.memberRoleRemove = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.memberNicknameUpdate = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.userAvatarUpdate = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.userUsernameUpdate = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.userDiscriminatorUpdate = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.channelCreate = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.channelRemove = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.channelUpdate = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.roleCreate = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.roleDelete = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.roleUpdate = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.emojiCreate = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.emojiDelete = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.emojiUpdate = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.threadCreate = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.threadDelete = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.threadUpdate = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.vcJoin = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.vcLeave = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.vcSwitch = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.vcMute = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.vcUnMute = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.vcDeafen = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.vcUnDeafen = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.guildBoostLevelUp = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.guildBoostLevelDown = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.guildPartnerAdd = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.guildPartnerRemove = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.guildMemberBoost = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.guildMemberUnBoost = {
            enabled: false,
            channel: ""
        };
        bot.commands.logging.guildBanAdd = {
            enabled: false,
            channel: ""
        };
    }
    if (!("embeds" in bot.commands.settings)) {
        bot.commands.settings.embeds = {
            "enabled": false,
            "colors": {
                "main": "#00ffff",
                "modlogs": "#00ffff",
                "warning": "#ff00c8",
                "music": "#00ffff",
                "games": "#00ffff"
            }
        };
    }

    if (!("avatar" in bot.commands.moderation)) {
        bot.commands.moderation.avatar = {
            enabled: false,
            permissions: { allowed_roles: [{ name: '@everyone', id: 'everyone' }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: [] }
        };

        bot.commands.moderation.deafen = {
            enabled: false,
            permissions: { allowed_roles: [{ name: '@everyone', id: 'everyone' }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: ['ADMINISTRATOR'] }
        };

        bot.commands.moderation.move = {
            enabled: false,
            permissions: { allowed_roles: [{ name: '@everyone', id: 'everyone' }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: ['ADMINISTRATOR'] }
        };

        bot.commands.moderation.slowmode = {
            enabled: false,
            permissions: { allowed_roles: [{ name: '@everyone', id: 'everyone' }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: ['ADMINISTRATOR'] }
        };

        bot.commands.moderation.undeafen = {
            enabled: false,
            permissions: { allowed_roles: [{ name: '@everyone', id: 'everyone' }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: ['ADMINISTRATOR'] }
        };

        bot.commands.moderation.vkick = {
            enabled: false,
            permissions: { allowed_roles: [{ name: '@everyone', id: 'everyone' }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: ['ADMINISTRATOR'] }
        };

        bot.commands.moderation.vmute = {
            enabled: false,
            permissions: { allowed_roles: [{ name: '@everyone', id: 'everyone' }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: ['ADMINISTRATOR'] }
        };

        bot.commands.moderation.vunmute = {
            enabled: false,
            permissions: { allowed_roles: [{ name: '@everyone', id: 'everyone' }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: ['ADMINISTRATOR'] }
        };
    }
    if (!("lang" in bot)) {
        bot.lang = 'en';
    }
    if (!("daily_points" in bot.commands.economy)) {
        bot.commands.economy.daily_points = 100;
    }
    if (!("shop" in bot.commands.economy)) {
        bot.commands.economy.shop = {
            "items": [{
                "name": "Apple Pie",
                "description": "A delicious apple pie!",
                "id": "kyzf5uypz70npq61z2r",
                "img": "https://ik.imagekit.io/botghost/kyzf5h3gwzcdgriaqdq_0Mq7RfaZa.png",
                "type": "static",
                "price": 50,
                "multiple": true,
                "useOnBuy": false,
                "destroyOnUse": false,
                "buyMessage": {
                    "type": "text",
                    "content": "You just bought some apple pie!"
                },
                "responses": [{
                    "type": "text",
                    "content": ""
                }],
                "roles": []
            }, {
                "name": "Burger",
                "description": "Nothing better than a juicy burger.",
                "id": "kyzf8n0zqq36h4usxil",
                "img": "https://ik.imagekit.io/botghost/kyzf8tjpn9h1mukftp_KLXWL-QoL.png",
                "type": "static",
                "price": "200",
                "multiple": true,
                "useOnBuy": false,
                "destroyOnUse": false,
                "buyMessage": {
                    "type": "text",
                    "content": "You just bought a burger!"
                },
                "responses": [{
                    "type": "text",
                    "content": ""
                }],
                "roles": []
            }, {
                "name": "Giant Gummy Bear",
                "description": "Why is it so big?",
                "id": "kyzfann7hylked6za27",
                "img": "https://ik.imagekit.io/botghost/kyzfakcodamgo8q63a6_j9Jzu5TvG.png",
                "type": "static",
                "price": "500",
                "multiple": false,
                "useOnBuy": false,
                "destroyOnUse": false,
                "buyMessage": {
                    "type": "text",
                    "content": "Lets hope this fits in your inventory."
                },
                "responses": [{
                    "type": "text",
                    "content": ""
                }],
                "roles": []
            }, {
                "name": "Waffles",
                "description": "Waffles!",
                "id": "kyzfbhi96oi24tw5afq",
                "img": "https://ik.imagekit.io/botghost/kyzfbbpw74gtcmj5xlb_eM-ptgvjZ.png",
                "type": "static",
                "price": "1000",
                "multiple": true,
                "useOnBuy": false,
                "destroyOnUse": false,
                "buyMessage": {
                    "type": "text",
                    "content": "Waffles!"
                },
                "responses": [{
                    "type": "text",
                    "content": ""
                }],
                "roles": []
            }, {
                "name": "Icecream",
                "description": "ICECREAM",
                "id": "kyzfki0ehsm81mbskda",
                "img": "https://ik.imagekit.io/botghost/kyzfkaquw9l0zztu8rb_6Uy2VsD7I.png",
                "type": "static",
                "price": "5000",
                "multiple": true,
                "useOnBuy": false,
                "destroyOnUse": false,
                "buyMessage": {
                    "type": "text",
                    "content": "ICECREAM"
                },
                "responses": [{
                    "type": "text",
                    "content": ""
                }],
                "roles": []
            }]
        };


        bot.commands.economy.viewshop = {
            enabled: true,
            permissions: { allowed_roles: [], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: [] }
        };

        bot.commands.economy.buy = {
            enabled: true,
            permissions: { allowed_roles: [], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: [] }
        };

        bot.commands.economy.inventory = {
            enabled: true,
            permissions: { allowed_roles: [], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: [] }
        };

        bot.commands.economy.use = {
            enabled: true,
            permissions: { allowed_roles: [], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: [] }
        };

        bot.commands.economy.giveitem = {
            enabled: true,
            permissions: { allowed_roles: [], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: [] }
        };

        bot.commands.economy.econadmin = {
            enabled: true,
            permissions: { allowed_roles: [], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: ['ADMINISTRATOR'] }
        };


    }

    if (!("timeout" in bot.commands.moderation)) {
        bot.commands.moderation.timeout = {
            enabled: true,
            permissions: { allowed_roles: [], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: ['ADMINISTRATOR'] }
        };

        bot.commands.moderation.removetimeout = {
            enabled: true,
            permissions: { allowed_roles: [], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: ['ADMINISTRATOR'] }
        };
    }

    if (!("statsChannels" in bot.commands)) {
        bot.commands.statsChannels = {
            enabled: false,
            "channels": {
                "textChannel": {
                    "enabled": false,
                },
                "voiceChannel": {
                    "enabled": false,
                },
                //   "humanChannel": {
                //       "enabled": false,
                //   },
                //   "botChannel": {
                //       "enabled": false,
                //   },
                "membersChannel": {
                    "enabled": false,
                },
                "roleChannel": {
                    "enabled": false,
                },
                "emojiChannel": {
                    "enabled": false,
                },
                "categoryChannel": {
                    "enabled": false,
                }
            },
            category_name: "Stats"
        };
    }
    if (!("membershipScreening" in bot.commands)) {
        bot.commands.membershipScreening = {
            enabled: false,
            roles: []
        };
    }


    if (!("responder" in bot.commands)) {
        bot.commands.responder = {
            enabled: false,
            cooldown: 30,
            messages: [],
            autoresponder: {
                enabled: true,
                permissions: { allowed_roles: [], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: ['ADMINISTRATOR'] }
            }
        };
    }
    if (!("status" in bot.commands)) {
        bot.commands.status = {
            enabled: false,
            duration: 10,
            statuses: []
        };
    }

    if (!(Array.isArray(bot.commands.announcements.ban))) {
        bot.commands.announcements = {
            join: [],
            ban: [],
            leave: []
        };
    }
    if (!('reaction_roles' in bot.commands)) {
        bot.commands.reaction_roles = {
            enabled: false,
            roles: []
        };
    }

    if (!("shuffle" in bot.commands.music)) {
        bot.commands.music.wipequeue = {
            "permissions": {
                "allowed_roles": [{
                    "name": "@everyone",
                    "id": "everyone"
                }],
                "banned_users": [],
                "banned_roles": [],
                "banned_channels": [],
                "required_permissions": []
            }
        };

        bot.commands.music.resume = {
            "permissions": {
                "allowed_roles": [{
                    "name": "@everyone",
                    "id": "everyone"
                }],
                "banned_users": [],
                "banned_roles": [],
                "banned_channels": [],
                "required_permissions": []
            }
        };

        bot.commands.music.connect = {
            "premissions": {
                "allowed_roles": [{
                    "name": "@everyone",
                    "id": "everyone"
                }],
                "banned_users": [],
                "banned_roles": [],
                "banned_channels": [],
                "required_permissions": []
            }
        };
        bot.commands.music.disconnect = {
            "permissions": {
                "allowed_roles": [{
                    "name": "@everyone",
                    "id": "everyone"
                }],
                "banned_users": [],
                "banned_roles": [],
                "banned_channels": [],
                "required_permissions": []
            }
        };
        bot.commands.music.loop = {
            "permissions": {
                "allowed_roles": [{
                    "name": "@everyone",
                    "id": "everyone"
                }],
                "banned_users": [],
                "banned_roles": [],
                "banned_channels": [],
                "required_permissions": []
            }
        };
        bot.commands.music.remove = {
            "premissions": {
                "allowed_roles": [{
                    "name": "@everyone",
                    "id": "everyone"
                }],
                "banned_users": [],
                "banned_roles": [],
                "banned_channels": [],
                "required_permissions": []
            }
        };

        bot.commands.music.seek = {
            "premissions": {
                "allowed_roles": [{
                    "name": "@everyone",
                    "id": "everyone"
                }],
                "banned_users": [],
                "banned_roles": [],
                "banned_channels": [],
                "required_permissions": []
            }
        };

        bot.commands.music.forward = {
            "permissions": {
                "allowed_roles": [{
                    "name": "@everyone",
                    "id": "everyone"
                }],
                "banned_users": [],
                "banned_roles": [],
                "banned_channels": [],
                "required_permissions": []
            }
        };

        bot.commands.music.shuffle = {
            "permissions": {
                "allowed_roles": [{
                    "name": "@everyone",
                    "id": "everyone"
                }],
                "banned_users": [],
                "banned_roles": [],
                "banned_channels": [],
                "required_permissions": []
            }
        };
    }
    // NEW FEATURES
    if (!('messenger' in bot.commands)) {
        bot.commands.messenger = {
            enabled: false,
            messages: []
        };
    }

    if (!('levels' in bot.commands)) {
        bot.commands.levels = {
            enabled: false,
            options: {
                exp_rate: 1,
                level_up: {
                    type: "current_channel",
                    messages: []
                },
                no_exp_channels: [],
                no_exp_roles: [],
                roles: []
            },

        };
    } else {
    }

    if ('levels' in bot.commands && (!bot.commands.levels.level || !bot.commands.levels.levels)) {
        // console.log("ADD LEVELS!");
        bot.commands.levels.level = {
            enabled: true,
            permissions: {
                allowed_roles: [],
                banned_roles: [],
                banned_channels: [],
                banned_users: [],
                required_permissions: [],
            },
        };

        bot.commands.levels.levels = {
            enabled: true,
            permissions: {
                allowed_roles: [],
                banned_roles: [],
                banned_channels: [],
                banned_users: [],
                required_permissions: [],
            }
        };
    }




    if (!('search' in bot.commands)) {
        bot.commands.search = {
            youtube: { enabled: false },
            twitch: { enabled: false },
            google: { enabled: false },
            imgur: { enabled: false },
            urban: { enabled: false }
        };
    }

    if (!("slash" in bot.commands)) {
        bot.commands.slash = {
            commands: []
        };
    }

    if (!('warn' in bot.commands.moderation)) {
        bot.commands.moderation.warn = {
            enabled: true,
            permissions: { allowed_roles: [], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: ['ADMINISTRATOR'] }
        };
    }

    if (!('warnings' in bot.commands.moderation)) {
        bot.commands.moderation.warnings = {
            enabled: true,
            permissions: { allowed_roles: [{ name: '@everyone', id: 'everyone' }], banned_roles: [], banned_users: [], banned_channels: [], required_permissions: [] }
        };
    }

    if (!('automod' in bot.commands.automod)) {
        bot.commands.automod.automod = {
            enabled: false,
            logging: {
                enabled: false,
                channel: "automod-logs"
            },
            "options": {
                enabled: false,
                "blacklist": {
                    "words": [
                    ],
                    "action": "disabled",
                    "ignored_channels": [],
                    "ignored_roles": []
                },
                "whitelist": {
                    "words": []
                },
                "links": {
                    "action": "disabled",
                    "ignored_channels": [],
                    "ignored_roles": []
                },
                "caps": {
                    "action": "disabled",
                    "ignored_channels": [],
                    "ignored_roles": []
                },
                "emoji": {
                    "allowed": 8,
                    "action": "disabled",
                    "ignored_channels": [],
                    "ignored_roles": []
                },
                "server_invites": {
                    "action": "disabled",
                    "ignored_channels": [],
                    "ignored_roles": []
                },
                "mentions": {
                    "allowed": 5,
                    "action": "disabled",
                    "ignored_channels": [],
                    "ignored_roles": []
                },
                ignore_bots: true,
                ignore_admins: true,
                dm: false,
                empty: "false"
            },
        };
    }

    if (!('enabled' in bot.commands.automod.automod)) {
        bot.commands.automod.automod.enabled = false;
    }

    if (!('logging' in bot.commands.automod.automod)) {
        bot.commands.automod.automod.logging = {
            enabled: false,
            channel: "automod-logs"
        };
    }

    if (!("autorole" in bot.commands)) {
        bot.commands.autorole = {
            enabled: false,
            roles: []
        };
    }

    if ('autorole' in bot.commands && !('enabled' in bot.commands.autorole)) {
        bot.commands.autorole.enabled = false;
    }


    if (!('clearwarnings' in bot.commands.moderation)) {
        bot.commands.moderation.clearwarnings = {
            enabled: false,
            permissions: { allowed_roles: [], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: ['ADMINISTRATOR'] }
        };
    }
    if (!('statusopt' in bot)) {
        bot.statusopt = {
            type: "LISTENING"
        };
    }

    if (!("cocreate" in bot.commands)) {
        bot.commands.cocreate = {
            cocreators: [],
            enabled: false

        };
    }

    if (!('music' in bot.commands)) {
        bot.commands.music = {
            enabled: false,
            play: {
                permissions: { allowed_roles: [{ name: '@everyone', id: 'everyone' }], banned_users: [], banned_roles: [], banned_channels: [], required_permissions: [] }
            },
            pause: {
                permissions: { allowed_roles: [{ name: '@everyone', id: 'everyone' }], banned_users: [], banned_roles: [], banned_channels: [], required_permissions: [] }
            },
            skip: {
                permissions: { allowed_roles: [{ name: '@everyone', id: 'everyone' }], banned_users: [], banned_roles: [], banned_channels: [], required_permissions: [] }
            },
            stop: {
                permissions: { allowed_roles: [{ name: '@everyone', id: 'everyone' }], banned_users: [], banned_roles: [], banned_channels: [], required_permissions: [] }
            },
            np: {
                permissions: { allowed_roles: [{ name: '@everyone', id: 'everyone' }], banned_users: [], banned_roles: [], banned_channels: [], required_permissions: [] }
            },
            queue: {
                permissions: { allowed_roles: [{ name: '@everyone', id: 'everyone' }], banned_users: [], banned_roles: [], banned_channels: [], required_permissions: [] }
            },
            resume: {
                permissions: { allowed_roles: [{ name: '@everyone', id: 'everyone' }], banned_users: [], banned_roles: [], banned_channels: [], required_permissions: [] }
            },
            volume: {
                permissions: { allowed_roles: [{ name: '@everyone', id: 'everyone' }], banned_users: [], banned_roles: [], banned_channels: [], required_permissions: [] }
            }
        };
    }

    if ("music" in bot.commands && (!bot.commands.music.pause)) {
        bot.commands.music.pause = {
            permissions: { allowed_roles: [{ name: '@everyone', id: 'everyone' }], banned_users: [], banned_roles: [], banned_channels: [], required_permissions: [] }
        };

    }

    if (!('errorlogs' in bot.commands.settings)) {
        bot.commands.settings.errorlogs = {
            enabled: false,
            channel_name: "System Errors"
        };
    }

    if (!('empty' in bot.commands.automod.automod.options)) {
        bot.commands.automod.automod.options.empty = "false";
    }
    if (!('advertise' in bot.commands)) {

        bot.commands.advertise = {
            enabled: false,
            short_description: '',
            long_description: "",
            extra_tags: [],
            support_server: ""
        };
    }

    if (!('cocreate' in bot.commands)) {
        bot.commands.cocreate = {
            enabled: false,
            cocreators: [],
            invite: ''
        };
    }

    if (!('meme_creator' in bot.commands)) {
        bot.commands.meme_creator = {
            enabled: false,
            memes: {
                permissions: { allowed_roles: [{ name: '@everyone', id: 'everyone' }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: [] }
            },
            create_meme: {
                permissions: { allowed_roles: [{ name: '@everyone', id: 'everyone' }], banned_roles: [], banned_channels: [], banned_users: [], required_permissions: [] }
            }
        };
    }
    return bot;
};