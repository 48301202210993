import React from 'react';
import { connect } from 'react-redux';

var IMAGES = [
    process.env.PUBLIC_URL + "/images/bp1.png",
];

export const BotPanelBanner = () => {
    const handleGetStarted = () => {
        window.open('https://botpanel.gg', '_blank');
    };

    return (
        <div className="relative overflow-hidden bg-[#0B0C0F] p-6 sm:p-12 rounded-2xl my-4 text-white">
            {/* Dark gradient overlay */}
            <div className="absolute inset-0 bg-gradient-to-r from-[#0B0C0F] via-[#0B0C0F] to-[#0d1117]" />

            {/* Enhanced Blue Accent Glows */}
            <div className="absolute -bottom-32 right-0 w-2/3 h-[150%] bg-gradient-to-tl from-[#179aff]/20 via-[#179aff]/10 to-transparent blur-3xl" />
            <div className="absolute top-0 right-0 w-1/2 h-full bg-gradient-to-bl from-[#179aff]/15 via-[#179aff]/5 to-transparent blur-2xl" />

            {/* Additional subtle blue accent */}
            <div className="absolute inset-0 bg-gradient-to-r from-transparent via-[#179aff]/[0.02] to-[#179aff]/[0.05]" />

            <div className="flex flex-col lg:flex-row items-center justify-between relative z-10 gap-8 lg:gap-0">
                {/* Content */}
                <div className="relative z-10 w-full lg:max-w-xl">
                    <h1 className="text-3xl sm:text-4xl font-bold mb-3">
                        Level up your Bot with{' '}
                        <span className="text-[#179aff] whitespace-nowrap inline-flex items-center gap-1">
                            <img
                                src="https://botpanel.gg/img/logo.png"
                                alt="BotPanel Logo"
                                className="h-6 sm:h-8 w-auto inline-block -mt-1"
                            />
                            BotPanel
                        </span>
                    </h1>

                    <p className="text-base sm:text-lg leading-relaxed text-gray-400 mb-5 lg:pr-12">
                        Let users manage your bot with ease. Create a custom dashboard and website for your bot. Get detailed analytics and more.
                    </p>

                    <button
                        onClick={handleGetStarted}
                        className="relative w-full sm:w-auto px-6 py-2.5 rounded-lg font-semibold overflow-hidden
                            transition-all duration-200 hover:-translate-y-0.5 active:translate-y-0 
                            group cursor-pointer"
                    >
                        {/* Gradient background */}
                        <div className="absolute inset-0 bg-gradient-to-r from-[#179aff] via-[#179aff] to-[#0284ff] 
                            group-hover:opacity-90 transition-opacity" />

                        {/* Shine effect */}
                        <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/10 to-transparent 
                            translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-1000" />

                        {/* Button shadow */}
                        <div className="absolute inset-0 shadow-xl shadow-[#179aff]/20" />

                        {/* Button text */}
                        <span className="relative z-10">Get Started</span>
                    </button>
                </div>

                {/* Dashboard Image - Hidden on mobile */}
                <div className="hidden lg:block relative z-10 w-[600px] pl-16">
                    <div className="transform scale-110">
                        <img
                            src={IMAGES[0]}
                            alt="BotPanel Dashboard"
                            className="w-full h-auto rounded-lg transform hover:scale-105 transition-transform duration-500"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BotPanelBanner);