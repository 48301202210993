import React, { Component } from "react";
import { connect } from "react-redux";
import DocsLink from "../../elements/DocsLink";
import Toggle from "../../elements/Toggle";
import { updateElementData } from "../../../../actions";

import TextArea from "../../elements/TextArea";
import RefreshBotData from "../../elements/RefreshBotData";
import Select from "../../elements/Select";
import RoleAdd from "../../elements/RoleAdd";
import VariableTextInput from "../VariableTextInput";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { successToast } from "../toast";

const CHANNEL_TYPES = [
	{ label: "Text Channel", value: "GUILD_TEXT" },
	{ label: "Voice Channel", value: "GUILD_VOICE" },
	{ label: "Category Channel", value: "GUILD_CATEGORY" },
	{ label: "News Channel", value: "GUILD_NEWS" }
];

let s4 = () => {
	return Math.floor((1 + Math.random()) * 0x10000)
		.toString(16)
		.substring(1);
};

export class CreateRoleAction extends Component {

	componentDidMount() {
		if (this.props.data == undefined) {
			// Generate a random string for the variable name


			this.props.updateElementData({
				data: {
					type: "create_role",
					name: "",
					color: "",
					hoist: false,
					mentionable: false,
					permissions: [],
					position: "",
					reason: "",
					success_handles: false,
					validated: false,
					variable: ""
				},
				id: this.props.id
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.data != undefined && this.props.data.success_handles == undefined) {
			this.updateData("success_handles", false);
		}
		if (this.props.data == undefined) {
			this.props.updateElementData({
				data: {
					type: "create_role",
					name: "",
					color: "",
					hoist: false,
					mentionable: false,
					permissions: [],
					position: "",
					reason: "",
					validated: false,
					variable: ""
				},
				id: this.props.id
			});
		} else {


			if (this.props.data.name != "") {
				if (this.props.data.validated === false) {
					this.updateData("validated", true);
				}
			} else {
				if (this.props.data.validated === true) {
					this.updateData("validated", false);
				}
			}
		}
	}

	updateData = (key, value) => {
		// console.log("VALUE", value);
		var data = { ...this.props.data };
		data[key] = value;
		this.props.updateElementData({
			data: data,
			id: this.props.id
		});
	};

	renderOptions = () => {
		var options = [
			// {value:"trigger",label:"The member who triggered the command"}
		];
		this.props.options.forEach((option) => {
			if (option.type == "channel") {
				options.push({ value: { variable: option.name }, label: `Channel Option: ${option.name}` });
			}
		});
		return options;
	};

	selectUpdate = (value) => {
		value = JSON.parse(value);
		var target = { ...this.props.data.target };
		target = value;
		this.updateData("target", target);
	};

	renderRoleOptions = () => {
		var options = [];
		options.push({ value: JSON.stringify({ id: "" }), label: "" });

		this.props.options.forEach((option) => {
			if (option.type == "role") {
				options.push({ value: JSON.stringify({ variable: option.name }), label: `Role Option: ${option.name}` });
			}
		});

		if (this.props.bot_data == undefined || this.props.bot_data.length == 0) {
			// options.push(<optgroup label="Refresh Server Data. Ensure your bot is in a server and click the refresh button above."></optgroup>)
		} else {
			this.props.bot_data.forEach((guild, index) => {
				const guild_options = [];
				guild.roles.forEach((role) => {
					// if(this.props.requireGuild == true){
					//     channel.guild = guild.id;
					// }
					options.push({ value: JSON.stringify(role), label: `${role.name} - ${guild.name}` });
				});
			});
		}

		return options;
	};

	render() {
		if (this.props.data != undefined) {
			return (
				<div>
					<div className="mb-15">
						<h4>Create a Role</h4>
						<p>Create a new Role in the server. Options and variables can be used for the reason. You can use the{" "}
							<strong style={{ color: "#f45142" }}>
								{`{${this.props.data.variable}}`}{" "}
								<FontAwesomeIcon
									icon={faClipboard}
									fixedWidth
									className="text-[#9d9b9b] text-sm hover:text-red hover:cursor-pointer"
									onClick={() => {
										navigator.clipboard.writeText(`{${this.props.data.variable}}`);
										successToast("Copied variable to clipboard");
									}}
								/>
							</strong>{" "}
							variable in other actions and conditions.</p>
					</div>
					<hr className='slash-hr' style={{ borderTop: "1px solid rgb(173, 181, 189)" }}></hr>
					<div className="mb-15">
						<h4 style={{ marginBottom: "0px" }}>Optional Variable</h4>
						<div className="section-content-header mb-15">An optional variable to return the role id of this created role. You can use this variable in other blocks.</div>
						<div className="long-input mb-15">
							<label>Variable</label>
							<input
								placeholder="my_role"
								className={`${this.props.data.variable == "" && this.props.saveFailed ? "command-required" : ""}`}
								onChange={(e) => {
									var value = e.target.value;
									value = value.toLowerCase();
									value = value.substring(0, 32);
									value = value.replace(" ", "_");
									const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
									value = value.replace(regexForNonAlphaNum, "");

									this.updateData("variable", value);
								}}
								type="text"
								value={this.props.data.variable ? this.props.data.variable : ""}
							/>
						</div>

						{this.props.data.variable && (
							<div>


								<h4>Role Id Variable</h4>
								<p className="mb-15" style={{
									color: "#fff",
									opacity: "0.7",
									fontWeight: 500
								}}>
									You can use the variable <span style={{ color: "#f45142", opacity: "1 !important" }}>{`{${this.props.data.variable}}`}</span>
									<FontAwesomeIcon
										icon={faClipboard}
										fixedWidth
										className='text-sm hover:text-red hover:cursor-pointer'
										onClick={() => {
											navigator.clipboard.writeText(`{${this.props.data.variable}}`);
											successToast("Copied variable to clipboard");
										}}
									/> in other blocks.
								</p>
							</div>
						)}
					</div>
					<hr className='slash-hr' style={{ borderTop: "1px solid rgb(173, 181, 189)" }}></hr>
					<div className="mb-15 slash-action">
						<h4>
							Name <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/create-a-role#name" />{" "}
						</h4>
						<span>The name of the new role.</span>
						<VariableTextInput
							required={true}
							label="Name"
							placeholder="Role Name"
							value={this.props.data.name}
							onChange={(value) => {
								this.updateData("name", value);
							}}
							slash_options={this.props.options}
						/>
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Reason <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/create-a-role#reason" />
						</h4>
						<span>An optional reason for creating the new role.</span>
						<VariableTextInput
							label="Reason"
							placeholder="Reason"
							value={this.props.data.reason}
							onChange={(value) => {
								this.updateData("reason", value);
							}}
							slash_options={this.props.options}
						/>
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Position <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/create-a-role#position" />{" "}
						</h4>
						<span>The position of the new role. Leave blank for default positioning.</span>
						<VariableTextInput
							label="Position"
							placeholder="Position"
							value={this.props.data.position}
							onChange={(value) => {
								this.updateData("position", value);
							}}
							slash_options={this.props.options}
						/>
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Color <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/create-a-role#color" />
						</h4>
						<span>The color of this role. Members use the color of the highest role they have on the roles list.</span>
						<VariableTextInput
							label="Color"
							placeholder="Color"
							value={this.props.data.color}
							onChange={(value) => {
								this.updateData("color", value);
							}}
							slash_options={this.props.options}
						/>
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Display role members seperately from online members <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/create-a-role#display-role-members-separately-from-online-members" />{" "}
						</h4>
						<span>Whether or not to hoist the role.</span>

						<div className="mt-15">
							<Toggle
								value={this.props.data.hoist}
								update={(value) => {
									this.updateData("hoist", value);
								}}
							/>
						</div>
					</div>

					<div className="mb-15 slash-action">
						<h4>
							Mentionable <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/create-a-role#mentionable" />
						</h4>
						<span>Allow anyone to @mention this role.</span>

						<div className="mt-15">
							<Toggle
								value={this.props.data.mentionable}
								update={(value) => {
									this.updateData("mentionable", value);
								}}
							/>
						</div>
					</div>

					<hr class="slash-hr" style={{ borderTop: "1px solid rgb(173, 181, 189)" }} />

					<div className="mb-15 slash-action">
						<h4>
							Permissions <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/create-a-role#permissions" />{" "}
						</h4>
						<span>Add Permissions for this role. The role will start with no default permissions.</span>

						<RoleAdd
							update={(items) => {
								var permissions = [...this.props.data.permissions];
								permissions = items;
								this.updateData("permissions", permissions);
							}}
							items={this.props.data.permissions}
							type={"permissions"}
						/>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = (state) => ({
	selected: state.builder.selected,
	elements: state.builder.elements,
	bot_data: state.data.bot_data,
	saveFailed: state.builder.saveFailed
});
const mapDispatchToProps = {
	updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateRoleAction);
