import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import history from "../../../history";
import server from "../../../api/server";
import { setOnlineStatus, setQuickStart, updateInactiveBot } from "../../../actions";

export class BotInactiveModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: true,
            isLoading: false
        };
    }

    start = async () => {
        this.setState({ isLoading: true });
        var obj = {
            bot: this.props.bot,
            id: this.props.bot.id,
        };

        obj.collab = this.props.collab ? true : false;

        const response = await server.post("/dashboard/start", obj);
        if (response.data && response.data.response == true) {
            this.props.setOnlineStatus(true);
        } else if (
            response.data &&
            response.data.response == false &&
            response.data.reason == "logged"
        ) {
            this.props.setAuth();
        } else if (response.data &&
            response.data.response == false &&
            response.data.reason == "premium") {
            window.location.reload();
        }
        var quick_start = { ...this.props.quick_start };
        quick_start["on_off"] = true;
        this.props.setQuickStart(quick_start);
        this.setState({ isLoading: false });
        this.setState({ open: false });
        return;
    };

    handleRestartBot = async () => {
        try {
            this.setState({ isLoading: true });
            await this.start();
        } catch (err) {
            console.error("Error restarting bot:", err);
        } finally {
            this.setState({ isLoading: false });
        }
    };

    render() {
        return (
            <Transition appear show={this.state.open} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-[9999]"
                    onClose={() => {
                        this.setState({ open: false });
                        this.props.updateInactiveBot(false);
                    }}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-[#1a1c1f]/90 z-[9999]" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto z-[9999]">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-lg bg-[#2b2d31] p-8 text-left align-middle shadow-xl transition-all border border-[#1a1c1f]">
                                    <div className="flex justify-center mb-6">
                                        <img
                                            src="/images/logo-red.png"
                                            alt="BotPanel Logo"
                                            className="w-12 h-12"
                                        />
                                    </div>
                                    <Dialog.Title
                                        as="h3"
                                        className="text-2xl font-bold text-center text-white"
                                    >
                                        Bot Shutdown due to Inactivity
                                    </Dialog.Title>
                                    <div className="mt-4">
                                        <p className="text-[#b5bac1] text-center text-base leading-relaxed">
                                            Your bot has gone offline due to 48 hours of inactivity.
                                            You can either upgrade to Premium to prevent this from happening,
                                            or make sure to use at least one command every 48 hours.
                                        </p>
                                    </div>

                                    <div className="mt-8 flex flex-col sm:flex-row gap-3 justify-center">
                                        <button
                                            onClick={() => {
                                                this.props.updateInactiveBot(false);
                                                history.push("/dashboard/premium");
                                                this.setState({ open: false });
                                            }}
                                            className="inline-flex justify-center items-center rounded-md bg-[#f0b232] hover:bg-[#ffbd3e] px-8 py-2.5 text-sm font-bold text-white transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed b transform hover:-translate-y-0.5 hover:shadow-lg"
                                            disabled={this.state.isLoading}
                                        >
                                            Upgrade to Premium
                                        </button>
                                        <button
                                            onClick={this.handleRestartBot}
                                            className="inline-flex justify-center items-center rounded-md bg-[#2f3136] px-8 py-2.5 text-sm font-medium text-white hover:bg-[#383a40] transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                                            disabled={this.state.isLoading}
                                        >
                                            {this.state.isLoading ? (
                                                <>
                                                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                    </svg>
                                                    Restarting...
                                                </>
                                            ) : (
                                                'Restart Bot'
                                            )}
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        );
    }
}

const mapStateToProps = (state) => ({
    bot: state.data.bot,
    collab: state.data.collab,
    quick_start: state.quick_start
});

export default connect(mapStateToProps, {
    setOnlineStatus,
    setQuickStart,
    updateInactiveBot
})(BotInactiveModal);