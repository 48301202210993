import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync";
import reduxThunk from 'redux-thunk';

import * as Sentry from "@sentry/react";




import App from './components/App';
import reducers from './reducers';

import history from './history';
import { Toaster } from 'react-hot-toast';
import "./index.css";
import styles from "./index.css";
const reduxStateSyncConfig = {};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleWares = [createStateSyncMiddleware(reduxStateSyncConfig), reduxThunk];
const store = createStore(reducers,
  composeEnhancers(applyMiddleware(...middleWares)));

initMessageListener(store);

Sentry.init({
  dsn: "https://73c3eaee1abeb0a05c6da58570c61134@o4508247498620928.ingest.us.sentry.io/4508247501373440",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.01,
});


ReactDOM.render(

  <Provider store={store}>
    <Toaster position="top-right" />
    <Router history={history}>
      <App></App>
    </Router>
  </Provider>

  , document.querySelector("#root"));
