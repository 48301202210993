import React, { Component } from "react";
import { connect } from "react-redux";
import Dashboard from "../Dashboard";
import CustomEventEditor from "../elements/CustomEventEditor";
import ModuleHeader from "../elements/ModuleHeader";
import SectionHeader from "../elements/SectionHeader";
import SlashCommand from "../elements/SlashCommand";
import SlashCommandEditor from "../elements/SlashCommandEditor";
import GroupEditor from "./SlashCommandsGrouping";

export class SlashCommands extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filter: "",
            showModal: false
        };
    }

    renderEvents = () => {
        const { custom_events, groups } = this.props;
        if (!custom_events || !custom_events.events) return null;

        const filter = this.state.filter.toLowerCase();

        // Group events
        const groupedEvents = {};
        const ungroupedEvents = [];
        custom_events.events.forEach((event, index) => {
            const name = event.name.toLowerCase() || event.type.toLowerCase();
            const type = event.type.toLowerCase();

            if (filter === "" || name.includes(filter) || type.includes(filter)) {
                const groupId = event.groupId;
                const group = groups.find(g => g.id === groupId);

                if (groupId && group) {
                    if (!groupedEvents[groupId]) groupedEvents[groupId] = [];
                    groupedEvents[groupId].push(
                        <CustomEventEditor key={index} mode={"event"} event={event} index={index} />
                    );
                } else {
                    ungroupedEvents.push(
                        <CustomEventEditor key={index} mode={"event"} event={event} index={index} />
                    );
                }
            }
        });

        // Render grouped events
        const renderedGroups = Object.entries(groupedEvents).map(([groupId, events]) => {
            const group = groups.find(g => g.id === groupId);
            return (
                <section key={groupId}>
                    <SectionHeader
                        title={group.name}
                        pretitle={group.description}
                    />
                    <div>{events}</div>
                </section>
            );
        });

        // Render ungrouped events at the bottom
        if (ungroupedEvents.length > 0) {
            renderedGroups.push(
                <section key="ungrouped">
                    <SectionHeader
                        title="Ungrouped"
                        pretitle="Ungrouped Events"
                    />
                    <div>{ungroupedEvents}</div>
                </section>
            );
        }

        return renderedGroups;
    };

    countActiveCommands = () => {
        var count = 0;
        this.props.custom_events.events.forEach((event) => {
            if (event.enabled == undefined || event.enabled == true) {
                count += 1;
            }
        });
        return count;
    };
    render() {
        if (!this.props.custom_events) return (
            <Dashboard>

            </Dashboard>
        );
        return (
            <Dashboard>
                <GroupEditor showModal={this.state.showModal} close={() => { this.setState({ showModal: false }); }} />
                <ModuleHeader
                    tutorial="https://docs.botghost.com/events/events"
                    showEnabled={false}
                    title="Custom Events"
                    description="Create custom events"
                    icon="customevents.png"
                    docs={"https://docs.botghost.com/events/events"}
                ></ModuleHeader>

                <section>
                    <SectionHeader
                        title="New Custom Event "
                        pretitle="Custom Events"
                    />

                    <SlashCommandEditor mode={"event"}></SlashCommandEditor>
                </section>

                <section>
                    <div className='flex flex-col md:flex-row gap-5 md:gap-0'>
                        <div>
                            <p className="section-pretitle">Custom Events</p>
                            <h2 className={`section-title ${!this.props.premium && this.countActiveCommands() >= 3 ? "!text-red" : ""}`}>Active Custom Events {this.props.premium == false
                                ? ` (${this.countActiveCommands()}/3)`
                                : ""
                            }</h2>
                        </div>

                        <div className='ml-auto flex items-center w-full md:w-auto'>
                            <button className="btn btn-primary h-10 whitespace-nowrap mr-2" onClick={() => { this.setState({ showModal: true }); }}>Event Group Editor</button>
                            <div className="long-input" style={{ width: "100%" }}>
                                <input
                                    style={{ backgroundColor: "#2f3136" }}
                                    type="text"
                                    placeholder="Search"
                                    value={this.state.filter}
                                    onChange={(e) => { this.setState({ filter: e.target.value }); }}
                                />
                            </div>
                        </div>
                    </div>

                    <div>{this.renderEvents()}</div>
                </section>
            </Dashboard>
        );
    }
}

const mapStateToProps = (state) => ({
    custom_events: state.data.bot.commands.custom_events,
    premium: state.data.premium,
    beta: state.data.beta,
    groups: state.data.bot.commands.commandGroups || [],
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SlashCommands);